import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './authcontext';
import fetchSpreadsheetData from './fetchspreadsheetdata';
import api from './api';
import ProposeCleaning from './proposecleaning';
import AutosuggestDropdown from './autosuggestdropdown';

const RowMappingPage = () => {
  const { spreadsheets, currentOrg, editedRows } = useAuth();
  const [showAllFields, setShowAllFields] = useState(true);
  const [selectedSpreadsheets, setSelectedSpreadsheets] = useState([]);
  const [expandedSuborgs, setExpandedSuborgs] = useState({});
  const [uniqueFields, setUniqueFields] = useState([]);
  const [selectedField, setSelectedField] = useState(''); // Track selected field
  const [uniqueValues, setUniqueValues] = useState([]); 
  const [editedValues, setEditedValues] = useState({});
  const [valueLocations, setValueLocations] = useState({}); // New state to track spreadsheets for each value
  const [columnConflicts, setColumnConflicts] = useState({}); // To track conflicting column statuses
  const [searchFilteredValues, setSearchFilteredValues] = useState([]); // New state to track filtered search results
  const [restrictDisplay, setRestrictDisplay] = useState(false); // Show/Hide Top N input and sum field
  const [topN, setTopN] = useState(''); // Top N input value
  const [numericalField, setNumericalField] = useState(''); // Numerical field to sum over


  const validColumnStatuses = ['Date', 'Categorical', 'NumericalEditable', 'CategoricalEditable'];

    // Toggle restrict display mode
    const toggleRestrictDisplay = () => {
      setRestrictDisplay(prev => !prev);
      setTopN('');  // Reset Top N when toggle is clicked
      setNumericalField('');
    };
  
    // Handle Top N input change
    const handleTopNChange = (e) => {
      const value = e.target.value;
      if (value === '' || /^[1-9]\d*$/.test(value)) { // Only positive integers allowed
        setTopN(value);
      }
    };
  
    // Handle the numerical field selection change
    const handleNumericalFieldSelection = (e) => {
      setNumericalField(e.target.value);
    };
  
    // Calculate sum for each value based on the selected numerical field
    const calculateTopNSortedValues = async () => {
      if (!numericalField || !topN || isNaN(parseInt(topN))) return; // No action if top N or numerical field isn't selected
    
      console.log(`Starting calculation for top ${topN} values based on numerical field: ${numericalField}`);
    
      const spreadsheetsToSearch = showAllFields
        ? spreadsheets.filter(spreadsheet => spreadsheet.organisationId === currentOrg.id)
        : selectedSpreadsheets.map(id => spreadsheets.find(s => s.id === id));
    
      const valueSums = {}; // To store the sum for each unique value
    
      // Iterate over each spreadsheet only once
      for (const spreadsheet of spreadsheetsToSearch) {
        if (spreadsheet.editedHeaders.includes(selectedField) && spreadsheet.editedHeaders.includes(numericalField)) {
          console.log(`Processing spreadsheet: ${spreadsheet.newName} (ID: ${spreadsheet.id})`);
    
          // Fetch spreadsheet data
          const data = await fetchSpreadsheetData(spreadsheet.id, spreadsheet, editedRows, false);
          console.log(`Fetched data for spreadsheet: ${spreadsheet.newName}`);

          console.log(data.filteredHeaders)
    
          const selectedFieldIndex = data.filteredHeaders.indexOf(selectedField);
          const numericalFieldIndex = data.filteredHeaders.indexOf(numericalField);
    
          // Ensure both the selected field and numerical field are present in the spreadsheet
          if (selectedFieldIndex !== -1 && numericalFieldIndex !== -1) {
            console.log(`Selected field index: ${selectedFieldIndex}, Numerical field index: ${numericalFieldIndex}`);
    
            // Iterate over rows and sum numerical field values for each unique original value
            for (const row of data.processedRows) {
              const originalValue = row[selectedFieldIndex];
              const numericalValue = parseFloat(row[numericalFieldIndex]) || 0;
    
              if (!valueSums[originalValue]) {
                valueSums[originalValue] = { originalValue, totalSum: 0, spreadsheetNames: new Set() };
              }
    
              // Accumulate the sum for the original value
              valueSums[originalValue].totalSum += numericalValue;
              valueSums[originalValue].spreadsheetNames.add(spreadsheet.newName);
    
              console.log(`Original value: ${originalValue}, Added ${numericalValue} to total. New sum: ${valueSums[originalValue].totalSum}`);
            }
          } else {
            console.log(`Skipping spreadsheet ${spreadsheet.newName} as it does not contain the selected field or numerical field.`);
          }
        }
      }
    
      // Convert valueSums object into an array and sort by total sum in descending order
      const sortedValues = Object.values(valueSums)
        .map((entry) => ({
          ...entry,
          spreadsheetNames: [...entry.spreadsheetNames].join(', '),
        }))
        .sort((a, b) => b.totalSum - a.totalSum);
    
      console.log(`Final sorted values:`, sortedValues);
    
      // Limit the results to the top N entries
      setSearchFilteredValues(sortedValues.slice(0, parseInt(topN)));
      console.log(`Displaying top ${topN} values:`, sortedValues.slice(0, parseInt(topN)));
    };    

  const toggleSuborgExpand = (suborg) => {
    setExpandedSuborgs((prevExpanded) => ({
      ...prevExpanded,
      [suborg]: !prevExpanded[suborg],
    }));
  };

  const handleSpreadsheetSelection = (spreadsheetId) => {
    setSelectedSpreadsheets((prevSelected) => {
      const updatedSelected = prevSelected.includes(spreadsheetId)
        ? prevSelected.filter((id) => id !== spreadsheetId)
        : [...prevSelected, spreadsheetId];
  
      if (updatedSelected.length === 0) {
        resetDropdownAndTable();
      } else if (selectedField && !updatedSelected.some((id) => {
        const spreadsheet = spreadsheets.find((s) => s.id === id);
        return spreadsheet?.editedHeaders.includes(selectedField);
      })) {
        resetDropdownAndTable();
      }

      return updatedSelected;
    });
  };

  const resetDropdownAndTable = () => {
    setSelectedField(''); 
    setUniqueValues([]); 
    setEditedValues({});
    setValueLocations({}); // Reset the value locations as well
    setColumnConflicts({}); // Reset the column conflicts as well
  };

  useEffect(() => {
    if (selectedField) {
      handleFieldSelection({ target: { value: selectedField } });
    }
  }, [selectedField]);
  

  const groupedSpreadsheets = spreadsheets.reduce((groups, spreadsheet) => {
    const suborg = spreadsheet.suborganisation || 'No Suborganisation';
    if (!groups[suborg]) {
      groups[suborg] = [];
    }
    groups[suborg].push(spreadsheet);
    return groups;
  }, {});

  useEffect(() => {
    const filterFieldsByStatus = (spreadsheet) => {
      const { editedHeaders, columnStatus } = spreadsheet;
      return editedHeaders.filter((_, index) => validColumnStatuses.includes(columnStatus[index]));
    };
  
    let selectedHeaders = [];
    let columnStatusMap = {}; // To track the statuses for each column
  
    if (showAllFields) {
      const allHeaders = spreadsheets
        .filter(spreadsheet => spreadsheet.organisationId === currentOrg.id)
        .flatMap(spreadsheet => {
          spreadsheet.editedHeaders.forEach((header, index) => {
            const status = spreadsheet.columnStatus[index];
            if (validColumnStatuses.includes(status)) {
              if (!columnStatusMap[header]) {
                columnStatusMap[header] = new Set();
              }
              columnStatusMap[header].add(status); // Track statuses for this column
            }
          });
          return filterFieldsByStatus(spreadsheet);
        });
  
      setUniqueFields([...new Set(allHeaders)]);
    } else if (selectedSpreadsheets.length > 0) {
      selectedHeaders = selectedSpreadsheets.flatMap((spreadsheetId) => {
        const spreadsheet = spreadsheets.find((s) => s.id === spreadsheetId);
        if (spreadsheet) {
          spreadsheet.editedHeaders.forEach((header, index) => {
            const status = spreadsheet.columnStatus[index];
            if (validColumnStatuses.includes(status)) {
              if (!columnStatusMap[header]) {
                columnStatusMap[header] = new Set();
              }
              columnStatusMap[header].add(status); // Track statuses for this column
            }
          });
          return filterFieldsByStatus(spreadsheet);
        }
        return [];
      });
  
      setUniqueFields([...new Set(selectedHeaders)]);
    } else {
      setUniqueFields([]);
    }
  
    // Detect and track conflicts in column statuses
    const conflictingColumns = Object.keys(columnStatusMap).reduce((acc, header) => {
      if (columnStatusMap[header].size > 1) {
        acc[header] = true; // Mark this column as conflicting
      }
      return acc;
    }, {});
  
    setColumnConflicts(conflictingColumns); // Set conflicts in state
  
    // Ensure selectedField remains valid and doesn't get reset unnecessarily
    if (selectedField && ![...new Set([...selectedHeaders, ...uniqueFields])].includes(selectedField)) {
      setSelectedField(''); // Reset selected field only if it's no longer available
    }
  
  }, [selectedSpreadsheets, spreadsheets, showAllFields, currentOrg]);
    

  // Handle the checkbox toggle and reset the dropdown, table, and checkboxes
  const handleShowAllFieldsToggle = () => {
    setShowAllFields((prevShowAllFields) => {
      const newShowAllFields = !prevShowAllFields;
  
      // Clear selected spreadsheets, dropdown, and table when toggled
      if (newShowAllFields) {
        setSelectedSpreadsheets([]); // Clear selected checkboxes when "Show fields from all spreadsheets" is checked
      }
  
      resetDropdownAndTable(); // Reset the dropdown and table
      return newShowAllFields;
    });
  };

  // Helper to initialize editedValues from uniqueValues
  const initializeEditedValues = (uniqueValuesArray) => {
    const initialEditedValues = {};
    uniqueValuesArray.forEach((row) => {
      const uniqueKey = `${row.originalValue}-${row.spreadsheetNames}`; // Create a unique key
      initialEditedValues[uniqueKey] = row.editedValue || row.originalValue;
    });
    return initialEditedValues;
  };

  const handleFieldSelection = async (event) => {
    const fieldName = event.target.value;
  
    // Prevent selection if the field has conflicts
    if (columnConflicts[fieldName]) {
      alert(`The field "${fieldName}" has conflicting data types across spreadsheets and cannot be selected.`);
      return; // Stop further processing if the field has conflicts
    }
  
    // Clear previous values immediately
    setSelectedField(fieldName);  // Set the selected field
    setUniqueValues([]);   // Clear old values immediately
    setSearchFilteredValues([]);  // Clear any previous search filters
  
    const spreadsheetsToSearch = showAllFields
      ? spreadsheets.filter(spreadsheet => spreadsheet.organisationId === currentOrg.id)
      : selectedSpreadsheets.map(id => spreadsheets.find(s => s.id === id));
  
    const rowsWithEdits = {};
    const valueLocations = {};  // Track spreadsheets for each unique (original + edited) pair
  
    // Step 1: Loop through selected spreadsheets to gather unique values and check edited rows
    for (const spreadsheet of spreadsheetsToSearch) {
      if (spreadsheet && spreadsheet.editedHeaders.includes(fieldName)) {
        try {
          const data = await fetchSpreadsheetData(spreadsheet.id, spreadsheet, editedRows, false);
          const fieldIndex = data.filteredHeaders.indexOf(fieldName);
  
          if (fieldIndex !== -1) {
            const values = data.processedRows.map((row) => row[fieldIndex]).filter(Boolean);
  
            values.forEach((value) => {
              const matchingEditedRows = editedRows.filter(
                (row) =>
                  row.editedColumnName === fieldName &&
                  row.spreadsheetId === spreadsheet.id &&
                  String(row.originalValue) === String(value)
              );
  
              if (matchingEditedRows.length > 0) {
                matchingEditedRows.forEach((editedRow) => {
                  const rowKey = `${value}-${editedRow.editedValue}`;
  
                  if (!rowsWithEdits[rowKey]) {
                    rowsWithEdits[rowKey] = {
                      originalValue: value,
                      editedValue: editedRow.editedValue,
                      spreadsheetNames: new Set([spreadsheet.newName]),
                    };
                  } else {
                    rowsWithEdits[rowKey].spreadsheetNames.add(spreadsheet.newName);
                  }
                });
              } else {
                const rowKey = `${value}-${value}`;
                if (!rowsWithEdits[rowKey]) {
                  rowsWithEdits[rowKey] = {
                    originalValue: value,
                    editedValue: value,
                    spreadsheetNames: new Set([spreadsheet.newName]),
                  };
                } else {
                  rowsWithEdits[rowKey].spreadsheetNames.add(spreadsheet.newName);
                }
              }
  
              if (!valueLocations[value]) {
                valueLocations[value] = new Set();
              }
              valueLocations[value].add(spreadsheet.newName);
            });
          }
        } catch (error) {
          console.error(`Error fetching data for spreadsheet ID: ${spreadsheet.id}`, error);
        }
      }
    }
  
    const finalRowsWithEdits = Object.values(rowsWithEdits).map((row) => ({
      originalValue: row.originalValue,
      editedValue: row.editedValue,
      spreadsheetNames: [...row.spreadsheetNames].join(', '),
    }));
  
    finalRowsWithEdits.sort((a, b) => naturalSort(a.editedValue, b.editedValue));
  
    // Update states only after processing
    setUniqueValues(finalRowsWithEdits);   // Update uniqueValues with the fetched data
    setSearchFilteredValues(finalRowsWithEdits);  // Update the table immediately with the new data
  
    const finalValueLocations = Object.fromEntries(
      Object.entries(valueLocations).map(([key, valueSet]) => [key, [...valueSet]])
    );
    setValueLocations(finalValueLocations);
  
    // Initialize editedValues when uniqueValues are set
    setEditedValues(initializeEditedValues(finalRowsWithEdits));
  };  
  
  // Helper function for natural sorting (numbers inside strings are compared correctly)
  const naturalSort = (a, b) => {
    const regex = /(\d+)|(\D+)/g;
    const aChunks = String(a).match(regex);
    const bChunks = String(b).match(regex);
  
    for (let i = 0; i < Math.max(aChunks.length, bChunks.length); i++) {
      const aChunk = aChunks[i] || '';
      const bChunk = bChunks[i] || '';
  
      // Compare numbers numerically
      if (/\d/.test(aChunk) && /\d/.test(bChunk)) {
        const diff = Number(aChunk) - Number(bChunk);
        if (diff !== 0) return diff;
      } else {
        // Compare non-numbers alphabetically
        const diff = aChunk.localeCompare(bChunk);
        if (diff !== 0) return diff;
      }
    }
    return 0;
  };
  

// Update handleEditedValueChange to use the unique key
const handleEditedValueChange = (uniqueKey, newValue) => {
  setEditedValues((prevEditedValues) => ({
    ...prevEditedValues,
    [uniqueKey]: newValue, // Update value for this specific unique key
  }));
};

const handleSaveChanges = async () => {
  // Check if the selected field is Numerical
  const isNumericalField = spreadsheets.some(spreadsheet => {
    const fieldIndex = spreadsheet.editedHeaders.indexOf(selectedField);
    return fieldIndex !== -1 && spreadsheet.columnStatus[fieldIndex] === 'NumericalEditable';
  });

  // If it's a Numerical field, validate that all edited values are numbers
  if (isNumericalField) {
    const invalidEntries = Object.values(editedValues).filter(
      (value) => isNaN(value) || value === '' // Check for non-numeric or empty values
    );

    if (invalidEntries.length > 0) {
      alert('Some entries contain non-numeric values, which are not allowed in numerical fields.');
      return;  // Stop saving if there are invalid entries
    }
  }

  // Filter out entries where original and edited values are the same
  const editedEntries = Object.keys(editedValues).filter((uniqueKey) => {
    const [originalValue] = uniqueKey.split('-'); // Split the key to get originalValue
    return String(originalValue) !== String(editedValues[uniqueKey]); // Compare original and edited values
  });

  if (editedEntries.length === 0) {
    alert('No changes to save.');
    return;
  }

  console.log('Edited values: ', editedValues);
  console.log('Unique values: ', uniqueValues);

  const spreadsheetsToSearch = showAllFields
    ? spreadsheets.filter(spreadsheet => spreadsheet.organisationId === currentOrg.id)
    : selectedSpreadsheets.map(id => spreadsheets.find(s => s.id === id));

  const spreadsheetsWithSelectedColumn = spreadsheetsToSearch.filter(spreadsheet =>
    spreadsheet.editedHeaders.includes(selectedField)
  );

  if (spreadsheetsWithSelectedColumn.length === 0) {
    console.log(`No selected spreadsheets found with the column: ${selectedField}`);
    return;
  }

  console.log('Spreadsheets with selected column:', spreadsheetsWithSelectedColumn);

  try {
    const spreadsheetDataCache = await Promise.all(
      spreadsheetsWithSelectedColumn.map(async (spreadsheet) => {
        const data = await fetchSpreadsheetData(spreadsheet.id, spreadsheet, editedRows, false);
        return { spreadsheetId: spreadsheet.id, data };  // Map spreadsheet ID to its data
      })
    );

    const spreadsheetDataMap = new Map(spreadsheetDataCache.map(item => [item.spreadsheetId, item.data]));

    const rowsToSave = [];

    for (const uniqueKey of editedEntries) {
      const [originalValue, spreadsheetNames] = uniqueKey.split('-'); // Parse the unique key
      const editedValue = editedValues[uniqueKey];

      for (const spreadsheet of spreadsheetsWithSelectedColumn) {
        // Make sure this spreadsheet is part of the edited entry
        if (!spreadsheetNames.includes(spreadsheet.newName)) continue;

        console.log(`Processing spreadsheet: ${spreadsheet.newName} (ID: ${spreadsheet.id})`);

        const data = spreadsheetDataMap.get(spreadsheet.id);
        const fieldIndex = spreadsheet.editedHeaders.indexOf(selectedField);
        const filteredIndex = data.filteredHeaders.indexOf(selectedField);

        const editedColumnName = selectedField;
        const originalColumnName = spreadsheet.originalHeaders[fieldIndex];

        console.log(`Original column name: ${originalColumnName}, Edited column name: ${editedColumnName}`);

        const matchingRows = data.processedRows.filter((row) => String(row[filteredIndex]) === String(originalValue));

        if (matchingRows.length > 0) {
          console.log(`Found matching rows in spreadsheet ${spreadsheet.newName}:`, matchingRows);

          // Step 1: Check if the edited value already exists in the EditedRows table
          const existingEditedRow = editedRows.find(
            (row) =>
              row.spreadsheetId === spreadsheet.id &&
              row.editedColumnName === editedColumnName &&
              String(row.originalValue) === String(originalValue)
          );

          if (existingEditedRow) {
            // If the row already exists, update the edited value
            console.log(`Updating existing row for original value: ${originalValue}`);
            rowsToSave.push({
              ...existingEditedRow,
              editedValue: String(editedValue),  // Update the edited value
            });
          } else {
            // If the row doesn't exist, add it as a new row
            console.log(`Adding new row for original value: ${originalValue}`);
            rowsToSave.push({
              spreadsheetId: spreadsheet.id,
              originalColumnName,
              editedColumnName,
              originalValue: String(originalValue),  // Ensure it's saved as a string
              editedValue: String(editedValue),       // Ensure it's saved as a string
            });
          }
        }
      }
    }

    if (rowsToSave.length > 0) {
      // Step 4: Send batch save request
      await api.post('/save-edited-rows', { rows: rowsToSave });
      alert('Changes saved successfully!');
      window.location.reload();
    } else {
      alert('No matching rows found to save.');
    }
  } catch (error) {
    console.error('Error saving changes:', error);
    alert('Error saving changes.');
  }
};

return (
  <div className="spreadsheet-selection-container">
    <div className="controls-container">
      {/* Show all fields checkbox */}
      <div className="input-group">
        <label>
          <input
            type="checkbox"
            checked={showAllFields}
            onChange={handleShowAllFieldsToggle}
          />
          Show fields from all spreadsheets
        </label>
      </div>

      {/* Folder system for selecting spreadsheets */}
      {!showAllFields && (
        <div className="input-group">
          {Object.keys(groupedSpreadsheets).map((suborg) => (
            <div key={suborg}>
              <div
                onClick={() => toggleSuborgExpand(suborg)}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <FontAwesomeIcon
                  icon={expandedSuborgs[suborg] ? faChevronDown : faChevronRight}
                  style={{ marginRight: '8px' }}
                />
                {suborg}
              </div>
              {expandedSuborgs[suborg] && (
                <div style={{ marginLeft: '20px' }}>
                  {groupedSpreadsheets[suborg].map((spreadsheet) => (
                    <div key={spreadsheet.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedSpreadsheets.includes(spreadsheet.id)}
                          onChange={() => handleSpreadsheetSelection(spreadsheet.id)}
                        />
                        {spreadsheet.newName}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Dropdown for unique fields */}
      <div className="input-group">
        <label htmlFor="fieldSelect">Select a field:</label>
        <select id="fieldSelect" onChange={handleFieldSelection} value={selectedField}>
          <option value="" disabled>Select a field</option>
          {uniqueFields.length > 0 ? (
            uniqueFields.map((field, idx) => (
              <option key={idx} value={field}>{field}</option>
            ))
          ) : (
            <option value="" disabled>No fields available</option>
          )}
        </select>
      </div>
    </div>

    {/* Restrict values button and top N input */}
    {selectedField && (
      <div className="restrict-display-container">
        <button onClick={toggleRestrictDisplay} disabled={!selectedField}>
          {restrictDisplay ? 'Hide Restrict Values' : 'Restrict Values Displayed'}
        </button>

        {restrictDisplay && (
          <div className="top-n-container">
            <label htmlFor="topNInput">Top N:</label>
            <input
              id="topNInput"
              type="number"
              min="1"
              value={topN}
              onChange={handleTopNChange}
              placeholder="Enter a positive integer"
            />
            <label htmlFor="sumFieldSelect">Select Numerical Field to Sum Over:</label>
            <select
              id="sumFieldSelect"
              value={numericalField}
              onChange={handleNumericalFieldSelection}
              disabled={!selectedField}
            >
              <option value="" disabled>Select a numerical field</option>
              {spreadsheets
                .filter(spreadsheet => {
                  const spreadsheetNames = searchFilteredValues.length > 0 ? searchFilteredValues : uniqueValues;
                  const relevantSpreadsheets = new Set(
                    spreadsheetNames.flatMap(row => row.spreadsheetNames.split(', '))
                  );
                  return relevantSpreadsheets.has(spreadsheet.newName);
                })
                .flatMap(spreadsheet =>
                  spreadsheet.editedHeaders.filter((_, index) =>
                    spreadsheet.columnStatus[index] === 'NumericalEditable'
                  )
                )
                .map((field, idx) => (
                  <option key={idx} value={field}>
                    {field}
                  </option>
                ))
              }
            </select>
            <button onClick={calculateTopNSortedValues}>Apply Restriction</button>
          </div>
        )}
      </div>
    )}

    {/* Search bar for filtering values after a field is selected */}
    {selectedField && uniqueValues.length > 0 && (
      <div className="search-bar input-group">
        <label htmlFor="valueSearch">Search values:</label>
        <AutosuggestDropdown
          options={[...new Set(
            uniqueValues.flatMap((row) => [row.originalValue, row.editedValue])
          )]}
          onSelect={(selectedValue) => {
            const filtered = uniqueValues.filter(
              (row) =>
                row.originalValue.toLowerCase().startsWith(selectedValue.toLowerCase()) ||
                row.editedValue.toLowerCase().startsWith(selectedValue.toLowerCase())
            );
            setSearchFilteredValues(filtered);  // Update state with filtered values
          }}
          showDropdown={true}
          initialValue=""
          isValid={true}
        />
      </div>
    )}

          {/* Propose Cleaning and Save buttons - only shown when a field is selected */}
          {selectedField && (
        <div className="buttons-group">
          <ProposeCleaning 
            uniqueValues={uniqueValues.map(value => ({ 
              ...value, 
              originalValue: String(value.originalValue), 
              editedValue: String(value.editedValue)
            }))} 
            setEditedValues={setEditedValues} 
          />
          <button className="save-button" onClick={handleSaveChanges}>Save all changes</button>
        </div>
      )}

    {/* Table displaying unique entries and input boxes */}
    {(searchFilteredValues.length > 0 ? searchFilteredValues : uniqueValues).length > 0 && (
      <div className="unique-values-table">
        <table>
          <thead>
            <tr>
              <th>Original Value</th>
              <th>Edited Value</th>
              <th>Spreadsheet(s)</th>
            </tr>
          </thead>
          <tbody>
            {(searchFilteredValues.length > 0 ? searchFilteredValues : uniqueValues).map((row, idx) => {
              const uniqueKey = `${row.originalValue}-${row.spreadsheetNames}`;
              return (
                <tr key={idx}>
                  <td>{row.originalValue}</td>
                  <td>
                    <input
                      type="text"
                      value={editedValues[uniqueKey] || row.editedValue || row.originalValue}
                      onChange={(e) => handleEditedValueChange(uniqueKey, e.target.value)}
                    />
                  </td>
                  <td>{row.spreadsheetNames}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )}
  </div>
);
};

export default RowMappingPage;
