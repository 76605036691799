import React, { useState, useEffect } from 'react';

const CustomPivotTableInModel = ({ pivotData, histPeriods, forePeriods, basis }) => {
  const [visiblePeriods, setVisiblePeriods] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set()); // Track expanded rows

  // Function to sort periods chronologically based on the grouping option (Monthly, Quarterly, Yearly)
  const sortPeriodsChronologically = (periods, groupingOption) => {
    if (groupingOption === 'Monthly') {
      return periods.sort((a, b) => {
        const [monthA, yearA] = a.split("/").map(Number);
        const [monthB, yearB] = b.split("/").map(Number);
        return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
      });
    } else if (groupingOption === 'Quarterly') {
      return periods.sort((a, b) => {
        const [quarterA, yearA] = a.split(" ");
        const [quarterB, yearB] = b.split(" ");
        const yearDiff = parseInt(yearA) - parseInt(yearB);

        if (yearDiff === 0) {
          const quarterOrder = { 'Q1': 1, 'Q2': 2, 'Q3': 3, 'Q4': 4 };
          return quarterOrder[quarterA] - quarterOrder[quarterB];
        }

        return yearDiff;
      });
    } else {
      return periods.sort((a, b) => a - b);
    }
  };

  // Effect to set visible periods from historical and forecast data
  useEffect(() => {
    if (!pivotData) {
      return;
    }

    const getPeriodsFromData = (data) => {
      const firstEntryKey = Object.keys(data)[0];
      if (firstEntryKey && data[firstEntryKey].total) {
        return Object.keys(data[firstEntryKey].total);
      }
      return [];
    };

    let histPeriodsList = [];
    let forePeriodsList = [];

    // Depending on the selected basis, we extract periods from both historical and forecast data
    switch (basis) {
      case 'Yearly':
        histPeriodsList = getPeriodsFromData(pivotData.annualHistData); // Get historical periods
        forePeriodsList = getPeriodsFromData(pivotData.annualForeData); // Get forecast periods
        break;
      case 'Quarterly':
        histPeriodsList = getPeriodsFromData(pivotData.quarterlyHistData);
        forePeriodsList = getPeriodsFromData(pivotData.quarterlyForeData);
        break;
      case 'Monthly':
        histPeriodsList = getPeriodsFromData(pivotData.monthlyHistData);
        forePeriodsList = getPeriodsFromData(pivotData.monthlyForeData);
        break;
      default:
        histPeriodsList = getPeriodsFromData(pivotData.annualHistData);
        forePeriodsList = getPeriodsFromData(pivotData.annualForeData);
    }

    // Sort periods based on the grouping option (Yearly, Quarterly, Monthly)
    histPeriodsList = sortPeriodsChronologically(histPeriodsList, basis);
    forePeriodsList = sortPeriodsChronologically(forePeriodsList, basis);

    // Adjust periods to match the model's histPeriods and forePeriods
    const adjustedPeriods = [
      ...histPeriodsList.slice(-histPeriods), // Only the last N historical periods
      ...forePeriodsList.slice(0, forePeriods) // First N forecast periods
    ];

    setVisiblePeriods(adjustedPeriods);
  }, [pivotData, histPeriods, forePeriods, basis]);

  // Toggle row expansion
  const toggleRowExpansion = (rowKey) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(rowKey)) {
        newExpandedRows.delete(rowKey);
      } else {
        newExpandedRows.add(rowKey);
      }
      return newExpandedRows;
    });
  };

  // Function to extract totals for the given periods from both historical and forecast data
  const getRowDataForPeriods = (rowData, periods) => {
    return periods.map((period) => {
      return rowData && rowData[period] ? rowData[period].sum : 0;
    });
  };

  // Recursive function to render rows and their children
  const renderRows = (data, depth = 0, parentKey = '') => {
    const sortedKeys = Object.keys(data).sort((a, b) => {
      const totalA = data[a].total ? Object.values(data[a].total).reduce((acc, period) => acc + (period.sum || 0), 0) : 0;
      const totalB = data[b].total ? Object.values(data[b].total).reduce((acc, period) => acc + (period.sum || 0), 0) : 0;
      return totalB - totalA; // Sort in descending order by total sum
    });

    return sortedKeys.map((key) => {
      const row = data[key];
      const isExpanded = expandedRows.has(parentKey + key);
      const hasChildren = row.children && Object.keys(row.children).length > 0;

      // Extract historical and forecast totals for the row
      const histRowValues = getRowDataForPeriods(row.total, visiblePeriods.slice(0, histPeriods));
      const foreRowValues = getRowDataForPeriods(row.total, visiblePeriods.slice(histPeriods));

      // Combine historical and forecast totals into one array
      const combinedRowValues = [...histRowValues, ...foreRowValues];

      return (
        <React.Fragment key={key}>
          <tr>
            <td style={{ paddingLeft: `${depth * 20}px` }} onClick={() => hasChildren && toggleRowExpansion(parentKey + key)}>
              {hasChildren ? (
                <span>{isExpanded ? '▼' : '►'}</span>
              ) : (
                <span style={{ visibility: 'hidden' }}>•</span>
              )}
              {key} {/* The key is the row name, e.g., Vendor A */}
            </td>
            <td>Fixed Amount</td>
            {combinedRowValues.map((value, index) => (
              <td key={index}>{value}</td>
            ))}
          </tr>
          {isExpanded && hasChildren && renderRows(row.children, depth + 1, parentKey + key)}
        </React.Fragment>
      );
    });
  };

  // We now render based on the correct dataset (historical/forecast) passed based on the basis
  return (
    <React.Fragment>
      {renderRows(
        basis === 'Yearly' ? pivotData.annualHistData :
        basis === 'Quarterly' ? pivotData.quarterlyHistData :
        pivotData.monthlyHistData
      )}
    </React.Fragment>
  );
};

export default CustomPivotTableInModel;
