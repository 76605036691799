import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../authcontext';

function ForecastingSidebar() {
  const { supermodels, setCurrentModel, models } = useAuth();
  const [expandedFolders, setExpandedFolders] = useState({});
  const [expandedSuperModels, setExpandedSuperModels] = useState({});
  const [expandedModels, setExpandedModels] = useState({}); // Track expanded models with parent info

  useEffect(() => {
    if (!Array.isArray(models)) {
      console.error("Models is not an array or is undefined:", models);
    }
  }, [models]);

  const toggleFolder = (folderName) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));
  };

  const toggleSuperModel = (superModelId) => {
    setExpandedSuperModels((prev) => ({
      ...prev,
      [superModelId]: !prev[superModelId], // Toggle supermodel expansion
    }));
  };

  const toggleModel = (modelId, parentModelId) => {
    setExpandedModels((prev) => {
        const updatedModels = { ...prev };

        // Collapse all sibling models of the same supermodel (P&L, BS, CF), but don't collapse the clicked model if it's already expanded
        Object.keys(updatedModels).forEach((id) => {
            if (updatedModels[id]?.parentModelId === parentModelId && id !== modelId.toString()) {
                updatedModels[id] = { isExpanded: false, parentModelId };
            }
        });

        // Toggle the clicked model, but prevent collapsing the same model if it's already expanded
        updatedModels[modelId] = {
            isExpanded: prev[modelId]?.isExpanded ? prev[modelId]?.isExpanded : true, 
            parentModelId,
        };

        return updatedModels;
    });
  };

  const handleModelClick = (model, parentModelId = null) => {
    setCurrentModel(model);

    // Only toggle the model without collapsing for P&L, BS, CF models
    if (['Profit and Loss', 'Balance Sheet', 'Cash Flow'].includes(model.modelType)) {
        toggleModel(model.id, parentModelId);
    } else {
        // For other models or supermodels, allow regular expansion/collapse
        toggleSuperModel(model.id);
    }
};

  // Ensure the supportingAssumptionModelIds is properly parsed as an array and filter invalid values
  const parseSupportingIds = (supportingAssumptionModelIds) => {
    try {
      const parsedIds = typeof supportingAssumptionModelIds === 'string' ? JSON.parse(supportingAssumptionModelIds) : supportingAssumptionModelIds;
      return Array.isArray(parsedIds) ? parsedIds.filter(id => id) : [];
    } catch (error) {
      console.error('Error parsing supportingAssumptionModelIds:', error);
      return [];
    }
  };

  // Recursive function to render nested supporting assumption models
  const renderSupportingModels = (model, level = 1) => {
    const supportingIds = parseSupportingIds(model.supportingAssumptionModelIds);

    return (
      <>
        {expandedModels[model.id]?.isExpanded && ( // Check if the model is expanded
          <div className={`sidebar-submenu level-${level}`}>
            {supportingIds
              .map((supportingId) => models.find((supportingModel) => supportingModel.id === parseInt(supportingId)))
              .filter((supportingModel) => supportingModel) // Filter out any undefined models
              .map((supportingModel) => (
                <div key={supportingModel.id}>
                  <NavLink
                    to={`/forecasting/${supportingModel.id}/sa`}
                    className="sidebar-button sidebar-indent"
                    onClick={() => handleModelClick(supportingModel, model.id)} // Pass parent model's id
                  >
                    {supportingModel.modelName}
                  </NavLink>

                  {/* Recursively render supporting assumptions of supporting assumptions */}
                  {renderSupportingModels(supportingModel, level + 1)}
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  // Grouping supermodels by folder
  const groupedSupermodels = supermodels.reduce((acc, supermodel) => {
    const folder = supermodel.modelFolder;
    if (!acc[folder]) {
      acc[folder] = [];
    }
    acc[folder].push(supermodel);
    return acc;
  }, {});

  return (
    <div className="sidebar">
      <NavLink to="/forecastinglanding" className="sidebar-button">Base Case Modelling</NavLink>
      {Object.entries(groupedSupermodels).map(([folderName, superModels]) => (
        <div key={folderName}>
          <button onClick={() => toggleFolder(folderName)} className="sidebar-button">
            {folderName}
          </button>
          {expandedFolders[folderName] && (
            <div className="sidebar-submenu">
              {superModels.map((superModel) => (
                <div key={superModel.id}>
                  <button onClick={() => toggleSuperModel(superModel.id)} className="sidebar-button">
                    {superModel.superModelName}
                  </button>
                  {expandedSuperModels[superModel.id] && ( // Toggle the supermodel expansion
                    <div className="sidebar-submenu">
                      {models
                        .filter(
                          (m) => m.superModelId === superModel.id && ['Profit and Loss', 'Balance Sheet', 'Cash Flow'].includes(m.modelType)
                        )
                        .map((model) => (
                          <div key={model.id}>
                            {/* Primary models (P&L, BS, CF) */}
                            <NavLink
                              to={`/forecasting/${model.id}/${model.modelType === 'Profit and Loss' ? 'pl' : 
                                                         model.modelType === 'Balance Sheet' ? 'bs' : 
                                                         model.modelType === 'Cash Flow' ? 'cf' : ''}`}
                              className="sidebar-button"
                              onClick={() => handleModelClick(model)}
                            >
                              {model.modelType === 'Profit and Loss' ? 'Profit & Loss' :
                               model.modelType === 'Balance Sheet' ? 'Balance Sheet' :
                               model.modelType === 'Cash Flow' ? 'Cash Flow' : model.modelName}
                            </NavLink>

                            {/* Supporting Assumptions */}
                            {renderSupportingModels(model)}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <div className="sidebar-spacer"></div> {/* Spacer added at the bottom */}
    </div>
  );
}

export default ForecastingSidebar;
