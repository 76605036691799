import React from 'react';
import CustomPivotTableInModel from './custompivottableinmodel'; // Import your custom pivot table component
import { useAuth } from '../authcontext';

const ModelRenderer = ({ rowsData, tableHeaders, histPeriods, forePeriods }) => {
  const { pivottables, currentModel } = useAuth();

  const renderRow = (row, rowIndex) => {
    if (Array.isArray(row.name) && row.name[0] === 'Pivot') {
      const pivotId = row.name[1];
      const pivotData = pivottables.find(pivot => pivot.id === pivotId);

      if (!pivotData) {
        return <tr key={rowIndex}><td colSpan={tableHeaders.length}>Pivot table not found or loading...</td></tr>;
      }

      return (
        <React.Fragment key={`pivot-${pivotId}`}>
          <CustomPivotTableInModel
            pivotData={pivotData}
            histPeriods={histPeriods}
            forePeriods={forePeriods}
            basis={currentModel.basis}
          />
        </React.Fragment>
      );
    }

    return (
      <tr key={rowIndex}>
        <td>{row.name}</td>
        <td>{row.driver}</td>
        {row.values.map((value, index) => <td key={index}>{value}</td>)}
      </tr>
    );
  };

  return (
    <table className='data-table data-table--model'>
      <thead>
        <tr>
          {tableHeaders.map((header, index) => <th key={index}>{header}</th>)}
        </tr>
      </thead>
      <tbody>
        {rowsData.map((row, rowIndex) => renderRow(row, rowIndex))}
      </tbody>
    </table>
  );
};

export default ModelRenderer;
