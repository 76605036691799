import React, { useState, useEffect } from 'react';
import { useAuth } from '../authcontext';
import api from '../api';
import { faPen, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calculatePeriods } from './calculateperiods';
import moment from 'moment';

const ForecastingLandingPage = () => {
    const { currentOrg, setCurrentOrg, supermodels, setSupermodels, models, setModels } = useAuth();
    const [defaultSettings, setDefaultSettings] = useState({
        defaultBasis: '',
        defaultHistPeriods: '',
        defaultForePeriods: '',
        defaultYearEnd: '',
    });
    const [newSuperModel, setNewSuperModel] = useState({
        modelName: '',
        folderName: '',
        profitAndLoss: false,
        balanceSheet: false,
        cashFlow: false,
    });
    const [folderOptions, setFolderOptions] = useState([]);
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState({});
    const [editedName, setEditedName] = useState({});

    useEffect(() => {
        if (currentOrg) {
            setDefaultSettings({
                defaultBasis: currentOrg.defaultBasis || '',
                defaultHistPeriods: currentOrg.defaultHistPeriods || '',
                defaultForePeriods: currentOrg.defaultForePeriods || '',
                defaultYearEnd: currentOrg.defaultYearEnd || '',
            });

            // Extract folder names from existing supermodels
            const folders = Array.from(new Set(supermodels.map(sm => sm.modelFolder)));
            setFolderOptions(folders);
        }
    }, [currentOrg, supermodels]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDefaultSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSuperModelChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewSuperModel((prevModel) => ({
            ...prevModel,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSaveDefaults = async () => {
        try {
            const response = await api.put(`/organisation/${currentOrg.id}/update-defaults`, defaultSettings);
            if (response.status === 200) {
                setCurrentOrg((prevOrg) => ({
                    ...prevOrg,
                    ...defaultSettings,
                }));
                window.location.reload();
            }
        } catch (error) {
            console.error('Failed to update default settings:', error);
        }
    };

    const transformPeriods = (periods) => {
        return periods.map(period => [
            period.label,
            moment(period.startDate).format('DD/MM/YYYY'),
            moment(period.endDate).format('DD/MM/YYYY')
        ]);
    };

    const handleAddModel = async (supermodel, modelType) => {
        const currentDate = new Date();
        const basis = supermodel.basis;
        const histPeriods = supermodel.histPeriods;
        const forePeriods = supermodel.forePeriods;
        const yearEnd = supermodel.yearEnd;
        const superModelName = supermodel.superModelName;
    
        // Calculate headers as done in the supermodel creation
        const annualHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Yearly', currentDate, 10, 0, yearEnd)));
        const quarterlyHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Quarterly', currentDate, 40, 0, yearEnd)));
        const monthlyHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Monthly', currentDate, 120, 0, yearEnd)));
    
        const annualForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Yearly', currentDate, 0, 10, yearEnd)));
        const quarterlyForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Quarterly', currentDate, 0, 40, yearEnd)));
        const monthlyForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Monthly', currentDate, 0, 120, yearEnd)));
    
        const emptyArray = '[]';
    
        try {
            const response = await api.post(`/supermodel/${supermodel.id}/add-model`, {
                organisationId: supermodel.organisationId,
                modelType,
                superModelName,
                basis,
                histPeriods,
                forePeriods,
                yearEnd,
                headers: {
                    annualHistHeaders,
                    quarterlyHistHeaders,
                    monthlyHistHeaders,
                    annualForeHeaders,
                    quarterlyForeHeaders,
                    monthlyForeHeaders,
                },
                rows: {
                    annualHistRows: emptyArray,
                    quarterlyHistRows: emptyArray,
                    monthlyHistRows: emptyArray,
                    annualForeRows: emptyArray,
                    quarterlyForeRows: emptyArray,
                    monthlyForeRows: emptyArray,
                },
                otherFields: {
                    rowNames: emptyArray,
                    rowVariables: emptyArray,
                    categories: emptyArray,
                    groupedOver: emptyArray,
                    dateColumn: emptyArray,
                    sourceFile: emptyArray,
                    drivers: emptyArray,
                    supportingAssumptionModelIds: emptyArray
                }
            });
    
            if (response.status === 201) {
                console.log('Model updated successfully')
                window.location.reload()
            }
        } catch (error) {
            console.error('Error adding model:', error);
        }
    };
    
    // Function to handle the edit icon click
    const handleEditClick = (modelId, currentName) => {
        setEditMode((prevState) => ({
            ...prevState,
            [modelId]: true,
        }));
        setEditedName((prevState) => ({
            ...prevState,
            [modelId]: currentName,
        }));
    };

    // Function to handle the save action
    const handleSaveName = async (modelId) => {
        try {
            await api.put(`/supermodel/${modelId}/update-name`, {
                superModelName: editedName[modelId],
            });
            setEditMode((prevState) => ({
                ...prevState,
                [modelId]: false,
            }));
            window.location.reload();
        } catch (error) {
            console.error('Error updating supermodel name:', error);
        }
    };
    
    const handleDeleteModel = async (supermodel, modelType) => {
        if (window.confirm('Are you sure you want to delete this model? This action is irreversible.')) {
            try {
                const model = models.find((m) => m.superModelId === supermodel.id && m.modelType === modelType);
                if (model) {
                    const response = await api.delete(`/supermodel/${supermodel.id}/delete-model/${model.id}`);
                    if (response.status === 200) {
                        console.log('Model deleted successfully')
                        window.location.reload()
                    }
                }
            } catch (error) {
                console.error('Error deleting model:', error);
            }
        }
    };  
    
    const handleDeleteSuperModel = async (supermodel) => {
        if (window.confirm(`Are you sure you want to delete the model "${supermodel.superModelName}"? This action is irreversible.`)) {
            try {
                const response = await api.delete(`/supermodel/${supermodel.id}/delete`);
                if (response.status === 200) {
                    console.log('Supermodel deleted successfully');
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error deleting supermodel:', error);
            }
        }
    };

    const renderModelToggle = (supermodel, modelType, isChecked) => (
        <span
            onClick={() => isChecked ? handleDeleteModel(supermodel, modelType) : handleAddModel(supermodel, modelType)}
            style={{
                display: 'inline-block',
                width: '24px',
                height: '24px',
                border: '1px solid #000',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isChecked ? 'green' : 'transparent',
                color: isChecked ? '#fff' : '#000',
                lineHeight: '24px',
                margin: '0 8px'
            }}
        >
            {isChecked ? '✔️' : ''}
        </span>
    );

    const renderDeleteButton = (supermodel) => (
        <span
            onClick={() => handleDeleteSuperModel(supermodel)}
            style={{
                color: 'red',
                cursor: 'pointer',
                margin: '0 8px',
            }}
        >
            Delete
        </span>
    );
    

    const handleCreateSuperModel = async () => {
        if (!newSuperModel.modelName || !newSuperModel.folderName || (!newSuperModel.profitAndLoss && !newSuperModel.balanceSheet && !newSuperModel.cashFlow)) {
            setError('Please fill in all fields and select at least one model type.');
            return;
        }
    
        const currentDate = new Date();
        const basis = defaultSettings.defaultBasis;
        const histPeriods = defaultSettings.defaultHistPeriods;
        const forePeriods = defaultSettings.defaultForePeriods;
        const yearEnd = defaultSettings.defaultYearEnd;
    
        const annualHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Yearly', currentDate, 10, 0, yearEnd)));
        const quarterlyHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Quarterly', currentDate, 40, 0, yearEnd)));
        const monthlyHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Monthly', currentDate, 120, 0, yearEnd)));
    
        const annualForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Yearly', currentDate, 0, 10, yearEnd)));
        const quarterlyForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Quarterly', currentDate, 0, 40, yearEnd)));
        const monthlyForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Monthly', currentDate, 0, 120, yearEnd)));
    
        const emptyArray = '[]';
    
        const modelTypes = [];
    
        if (newSuperModel.profitAndLoss) {
            modelTypes.push('Profit and Loss');
        }
        if (newSuperModel.balanceSheet) {
            modelTypes.push('Balance Sheet');
        }
        if (newSuperModel.cashFlow) {
            modelTypes.push('Cash Flow');
        }
    
        try {
            const response = await api.post('/supermodel/create', {
                organisationId: currentOrg.id,
                superModelName: newSuperModel.modelName,
                modelFolder: newSuperModel.folderName,
                basis,
                histPeriods,
                forePeriods,
                yearEnd,
                modelTypes,
                headers: {
                    annualHistHeaders,
                    quarterlyHistHeaders,
                    monthlyHistHeaders,
                    annualForeHeaders,
                    quarterlyForeHeaders,
                    monthlyForeHeaders,
                },
                rows: {
                    annualHistRows: emptyArray,
                    quarterlyHistRows: emptyArray,
                    monthlyHistRows: emptyArray,
                    annualForeRows: emptyArray,
                    quarterlyForeRows: emptyArray,
                    monthlyForeRows: emptyArray,
                },
                otherFields: {
                    rowNames: emptyArray,
                    rowVariables: emptyArray,
                    categories: emptyArray,
                    groupedOver: emptyArray,
                    dateColumn: emptyArray,
                    sourceFile: emptyArray,
                    drivers: emptyArray,
                    supportingAssumptionModelIds: emptyArray
                }
            });
    
            if (response.status === 201) {
                setSupermodels((prevSupermodels) => [...prevSupermodels, response.data.superModel]);
                setNewSuperModel({
                    modelName: '',
                    folderName: '',
                    profitAndLoss: false,
                    balanceSheet: false,
                    cashFlow: false,
                });
    
                window.location.reload()

            } else {
                setError('Failed to create super model. Please try again.');
            }
        } catch (error) {
            console.error('Failed to create super model:', error);
            setError('Failed to create super model. Please try again.');
        }
    };
      

    const groupedSupermodels = supermodels.reduce((acc, supermodel) => {
        const folder = supermodel.modelFolder;
        if (!acc[folder]) {
            acc[folder] = [];
        }
        acc[folder].push(supermodel);
        return acc;
    }, {});

    return (
        <div className="forecasting-landing-page">
            <h1>Forecasting Dashboard</h1>

            <div className="default-settings">
                <h2>Default Model Settings</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>Basis:</td>
                            <td>
                                <select
                                    name="defaultBasis"
                                    value={defaultSettings.defaultBasis}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Basis</option>
                                    <option value="Yearly">Yearly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Monthly">Monthly</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Historical Periods:</td>
                            <td>
                                <input
                                    type="number"
                                    name="defaultHistPeriods"
                                    value={defaultSettings.defaultHistPeriods}
                                    onChange={handleInputChange}
                                    min="1"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Forecasting Periods:</td>
                            <td>
                                <input
                                    type="number"
                                    name="defaultForePeriods"
                                    value={defaultSettings.defaultForePeriods}
                                    onChange={handleInputChange}
                                    min="1"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Financial Year End:</td>
                            <td>
                                <input
                                    type="date"
                                    name="defaultYearEnd"
                                    value={defaultSettings.defaultYearEnd}
                                    onChange={handleInputChange}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button onClick={handleSaveDefaults}>Save Defaults</button>
            </div>

            <div className="new-supermodel">
                <h2>Create New Model</h2>
                <input
                    type="text"
                    name="modelName"
                    placeholder="Model Name"
                    value={newSuperModel.modelName}
                    onChange={handleSuperModelChange}
                    autoComplete="off"
                />

                <input
                    list="folderSuggestions"
                    name="folderName"
                    placeholder="Folder Name"
                    value={newSuperModel.folderName}
                    onChange={handleSuperModelChange}
                    autoComplete="off"
                />
                <datalist id="folderSuggestions">
                    {folderOptions.map((folder, index) => (
                        <option key={index} value={folder}>
                            {folder}
                        </option>
                    ))}
                </datalist>

                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="profitAndLoss"
                            checked={newSuperModel.profitAndLoss}
                            onChange={handleSuperModelChange}
                        />
                        Profit and Loss
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="balanceSheet"
                            checked={newSuperModel.balanceSheet}
                            onChange={handleSuperModelChange}
                        />
                        Balance Sheet
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="cashFlow"
                            checked={newSuperModel.cashFlow}
                            onChange={handleSuperModelChange}
                        />
                        Cash Flow
                    </label>
                </div>
                <button onClick={handleCreateSuperModel}>Create Model</button>
            </div>

            <div className="supermodels-table">
                <h2>Models</h2>
                <div className='scrollable-table-container'>
                    <table className='data-table'>
                        <thead>
                            <tr>
                                <th>Model Name</th>
                                <th>Profit and Loss</th>
                                <th>Balance Sheet</th>
                                <th>Cash Flow</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(groupedSupermodels).map(([folder, models]) => (
                                <React.Fragment key={folder}>
                                    <tr>
                                        <td colSpan="5" style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                            {folder}
                                        </td>
                                    </tr>
                                    {models.map(model => (
                                        <tr key={model.id}>
                                            <td>
                                                {editMode[model.id] ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={editedName[model.id] || model.superModelName}
                                                            onChange={(e) =>
                                                                setEditedName((prevState) => ({
                                                                    ...prevState,
                                                                    [model.id]: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            style={{ marginLeft: '8px', color: 'green', cursor: 'pointer' }}
                                                            onClick={() => handleSaveName(model.id)}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {model.superModelName}
                                                        <FontAwesomeIcon
                                                            icon={faPen}
                                                            style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                            onClick={() => handleEditClick(model.id, model.superModelName)}
                                                        />
                                                    </>
                                                )}
                                            </td>                                            <td>{renderModelToggle(model, 'Profit and Loss', model.profitAndLoss)}</td>
                                            <td>{renderModelToggle(model, 'Balance Sheet', model.balanceSheet)}</td>
                                            <td>{renderModelToggle(model, 'Cash Flow', model.cashFlow)}</td>
                                            <td>{renderDeleteButton(model)}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ForecastingLandingPage;
