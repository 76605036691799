import React, { useState, useEffect } from 'react';

const Popup = ({ headers, rowData, forePeriods, currentModel, rowIndex, onClose, onValueChange, onSave }) => {
  const startForecastingIndex = rowData.values.length - forePeriods;

  // Use a stable rowIndex to prevent losing it during updates
  const [stableRowIndex] = useState(rowIndex);
  console.log("stableIndex: ", stableRowIndex)

  // Row setup data
  const rowDetails = currentModel && currentModel.sourceFile
    ? {
        spreadsheet: currentModel.sourceFile[stableRowIndex]?.[2] || 'N/A',
        category: currentModel.categories[stableRowIndex] || 'N/A',
        categoryValue: currentModel.rowVariables[stableRowIndex] || 'N/A',
        variable: currentModel.groupedOver[stableRowIndex] || 'N/A',
        dateColumn: currentModel.dateColumn[stableRowIndex] || 'N/A',
      }
    : {};

  const [forecastPercents, setForecastPercents] = useState([]);
  const [forecastValues, setForecastValues] = useState([]);

  useEffect(() => {
    if (rowData.driver === 'Percent') {
      let forecastPercentData = [];
      switch (currentModel.basis) {
        case 'Yearly':
          forecastPercentData = JSON.parse(currentModel.annualForeRows)[stableRowIndex].slice(0, forePeriods);
          break;
        case 'Quarterly':
          forecastPercentData = JSON.parse(currentModel.quarterlyForeRows)[stableRowIndex].slice(0, forePeriods);
          break;
        case 'Monthly':
          forecastPercentData = JSON.parse(currentModel.monthlyForeRows)[stableRowIndex].slice(0, forePeriods);
          break;
        default:
          break;
      }
      setForecastPercents(forecastPercentData);
      recalculateForecastValues(forecastPercentData);
    }
  }, [rowData, currentModel, forePeriods, stableRowIndex]);

  const recalculateForecastValues = (percents) => {
    const lastHistoricalValue = parseFloat(rowData.values[startForecastingIndex - 1]) || 0;
    let newForecastValues = [];
    let currentValue = lastHistoricalValue;

    percents.forEach((percent) => {
      const percentValue = parseFloat(percent) || 0;
      currentValue += (currentValue * percentValue) / 100;
      newForecastValues.push(currentValue.toFixed(2));
    });

    setForecastValues(newForecastValues);
  };

  const handlePercentChange = (index, value) => {
    const updatedPercents = [...forecastPercents];
    updatedPercents[index] = value;
    setForecastPercents(updatedPercents);
    recalculateForecastValues(updatedPercents);
  };

  const handleSave = () => {
    let updatedForeRows;

    switch (currentModel.basis) {
      case 'Yearly':
        updatedForeRows = [...JSON.parse(currentModel.annualForeRows)];
        break;
      case 'Quarterly':
        updatedForeRows = [...JSON.parse(currentModel.quarterlyForeRows)];
        break;
      case 'Monthly':
        updatedForeRows = [...JSON.parse(currentModel.monthlyForeRows)];
        break;
      default:
        return;
    }

    if (!Array.isArray(updatedForeRows[stableRowIndex])) {
      updatedForeRows[stableRowIndex] = new Array(40).fill(""); 
    }

    let updatedRow = [...updatedForeRows[stableRowIndex]];

    if (rowData.driver === 'Percent') {
      updatedRow.splice(0, forecastPercents.length, ...forecastPercents);
    } else {
      const forecastValues = rowData.values.slice(-forePeriods);
      updatedRow.splice(0, forecastValues.length, ...forecastValues.map(val => String(val)));
      while (updatedRow.length < 40) {
        updatedRow.push("");
      }
    }

    console.log("Saving stableRowIndex: ", stableRowIndex);
    console.log("Updated row being saved: ", updatedRow);

    onSave({
      rowIndex: stableRowIndex,
      updatedValues: updatedRow,
      basis: currentModel.basis
    });
  };

  const calculatePercentChange = (currentValue, previousValue) => {
    if (previousValue === 0 || currentValue === '') return '';
    return (((currentValue - previousValue) / previousValue) * 100).toFixed(2);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>×</button>

        <h2>{rowData.name}</h2>

        <div className="popup-row-details">
          <p><strong>Spreadsheet:</strong> {rowDetails.spreadsheet}</p>
          <p><strong>Category:</strong> {rowDetails.category}</p>
          <p><strong>Category Value:</strong> {rowDetails.categoryValue}</p>
          <p><strong>Variable summed over:</strong> {rowDetails.variable}</p>
          <p><strong>Date Column:</strong> {rowDetails.dateColumn}</p>
        </div>

        <table className="popup-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowData.driver === 'Percent' ? (
              <>
                <tr>
                  <td>{rowData.name}</td>
                  <td>Percent Change</td>
                  {rowData.values.map((value, index) => {
                    const isForecastingColumn = index >= startForecastingIndex;
                    if (index === 0) return <td key={index}></td>;
                    const previousValue = rowData.values[index - 1];
                    return isForecastingColumn ? (
                      <td key={index}>
                        <input
                          type="text"
                          value={forecastPercents[index - startForecastingIndex] || ''}
                          onChange={(e) => handlePercentChange(index - startForecastingIndex, e.target.value)}
                        />
                      </td>
                    ) : (
                      <td key={index}>
                        <span>{calculatePercentChange(value, previousValue)}</span>
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <td></td>
                  <td>Actual Values</td>
                  {rowData.values.map((value, index) => {
                    const isForecastingColumn = index >= startForecastingIndex;
                    return (
                      <td key={index}>
                        <span>{isForecastingColumn ? forecastValues[index - startForecastingIndex] : value}</span>
                      </td>
                    );
                  })}
                </tr>
              </>
            ) : (
              <tr>
                <td>{rowData.name}</td>
                <td>{rowData.driver}</td>
                {rowData.values.map((value, index) => {
                  const isForecastingColumn = index >= startForecastingIndex;
                  return (
                    <td key={index}>
                      {isForecastingColumn ? (
                        <input
                          type="text"
                          value={value}
                          onChange={(e) => onValueChange(index, e.target.value)}
                        />
                      ) : (
                        <span>{value}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        </table>

        <button className="popup-save" onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};

export default Popup;
