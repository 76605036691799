import React, { useState, useEffect } from 'react';
import CustomPivotTable from './pivottable';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuth } from './authcontext';
import moment from 'moment';
import fetchSpreadsheetData from './fetchspreadsheetdata';

const DraggableField = ({ field, index, moveField, handleTopNChange, topN, handleRemoveField }) => {
  const [, ref] = useDrag({
    type: 'FIELD',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'FIELD',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveField(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} className="draggable-field" style={{ display: 'flex', alignItems: 'center' }}>
      <span>{field}</span>
      <input 
        type="number" 
        value={topN} 
        min="1"
        placeholder="Top N"
        onChange={(e) => handleTopNChange(index, e.target.value)}
        style={{ marginLeft: '10px', width: '50px' }}
      />
      <span 
        onClick={() => handleRemoveField(field)} 
        style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}>
        &#x2716;
      </span> {/* Red Cross Icon */}
    </div>
  );
};

function DataAnalysisPage() {
  const { currentOrg, spreadsheets, setUploadedData, currentSpreadsheet, setCurrentSpreadsheet, editedRows } = useAuth();
  const [expandedSuborgs, setExpandedSuborgs] = useState({});  // For expanding/collapsing suborgs
  const [selectedSpreadsheets, setSelectedSpreadsheets] = useState([]);  // To track selected spreadsheets for consolidation
  const [filteredFields, setFilteredFields] = useState([]);
  const [originalCatFields, setOriginalCatFields] = useState([]); // New state to hold the original categorical fields
  const [filteredData, setFilteredData] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]); // Derived filtered rows state
  const [dateColumns, setDateColumns] = useState([]);
  const [sumFields, setSumFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [topNValues, setTopNValues] = useState([]);
  const [selectedDateField, setSelectedDateField] = useState('');
  const [selectedSumField, setSelectedSumField] = useState('');
  const [groupingOption, setGroupingOption] = useState('Yearly');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [analysisMode, setAnalysisMode] = useState('single'); // New state to toggle between single and consolidation modes


  const handleAnalysisModeChange = (e) => {
    const newMode = e.target.value;
    setAnalysisMode(newMode);
  
    // Reset relevant states when switching modes
    setCurrentSpreadsheet(null); // Reset current spreadsheet
    setSelectedSpreadsheets([]); // Reset selected spreadsheets for consolidation
    setSelectedFields([]);       // Reset selected fields
    setTopNValues([]);           // Reset top N values
    setSelectedDateField('');    // Reset selected date field
    setSelectedSumField('');     // Reset selected sum field
    setFilteredData(null);       // Clear filtered data
    setFilteredRows([]);         // Clear filtered rows
    setFilteredFields([]);
    setDateColumns([]);          // Clear date columns
    setSumFields([]);            // Clear sum fields
    setFromDate('');             // Reset fromDate
    setToDate('');               // Reset toDate
    
  };

  useEffect(() => {
    if (analysisMode === 'single' && currentSpreadsheet) {
      handleFetchSingleSpreadsheet();
    } else if (analysisMode === 'consolidation' && selectedSpreadsheets.length > 0) {
      consolidateSpreadsheets();
    }
  }, [currentSpreadsheet, selectedSpreadsheets, analysisMode]);

  const handleFetchSingleSpreadsheet = async () => {
    if (!currentSpreadsheet) return;
  
    try {
      const data = await fetchSpreadsheetData(currentSpreadsheet.id, currentSpreadsheet, editedRows, true, true);
  
      // Set default selected date field if not already set
      if (!selectedDateField && data.dateCols.length > 0) {
        setSelectedDateField(data.dateCols[0]); // Automatically select the first date field
      }
      
      // Set default selected sum field if not already set
      if (!selectedSumField && data.numCols.length > 0) {
        setSelectedSumField(data.numCols[0]); // Automatically select the first sum field
      }

      // Store original categorical fields just like in consolidation mode
      setOriginalCatFields(data.catCols);
  
      // Debugging output for the fetched data
      console.log("Fetched data:", data);
      console.log("Filtered Headers:", data.filteredHeaders);
      console.log("Processed Rows:", data.processedRows);
      console.log("Date Columns:", data.dateCols);
  
      // Make sure all state updates are logged after setting
      setUploadedData({
        headers: data.filteredHeaders,
        rows: data.processedRows,
      });
  
      setFilteredData({
        headers: data.filteredHeaders,
        rows: data.processedRows,
      });
  
      setDateColumns(data.dateCols);
      setSumFields(data.numCols);
      setFilteredFields(data.catCols);
  
    } catch (error) {
      console.error("Error fetching single spreadsheet data:", error);
    }
  };  


  // Calculate and set fromDate and toDate based on the selected date field
useEffect(() => {
  if (!filteredData || !selectedDateField) {
    console.log("Skipping date range calculation due to missing data or selectedDateField");
    return;
  }

  const dateColumnIndex = filteredData.headers.indexOf(selectedDateField);
  if (dateColumnIndex !== -1) {
    const dates = filteredData.rows
      .map(row => row[dateColumnIndex])
      .filter(date => moment(date, 'DD/MM/YYYY', true).isValid())
      .map(date => moment(date, 'DD/MM/YYYY'));

    if (dates.length > 0) {
      const earliestDate = moment.min(dates);
      const latestDate = moment.max(dates);

      setFromDate(earliestDate.format('YYYY-MM-DD'));
      setToDate(latestDate.format('YYYY-MM-DD'));
    }
  }
}, [filteredData, selectedDateField]);


// Filter data by selected date field and date range
useEffect(() => {
  if (!filteredData || !selectedDateField || !fromDate || !toDate) {
    console.log("One or more required conditions are missing", {
      filteredData,
      selectedDateField,
      fromDate,
      toDate,
    });
    return; // Skip filtering if any required value is missing
  }

  console.log('Running filtering logic with:', { selectedDateField, fromDate, toDate });

  const dateColumnIndex = filteredData.headers.indexOf(selectedDateField);

  if (dateColumnIndex !== -1) {
    const newFilteredRows = filteredData.rows.filter(row => {
      const dateValue = row[dateColumnIndex];

      // Ensure the date is parsed correctly
      const parsedDate = moment(dateValue, 'DD/MM/YYYY', true);

      // Check if the date is within the selected range
      const isInRange = parsedDate.isValid() && parsedDate.isBetween(fromDate, toDate, null, '[]');

      return isInRange;
    });

    setFilteredRows(newFilteredRows);
    console.log('Filtered Rows:', newFilteredRows);
  } else {
    console.warn("Selected date field not found in filtered headers.");
  }
}, [filteredData, selectedDateField, fromDate, toDate]);

// Effect to filter rows and update available fields dynamically
useEffect(() => {
  if (!filteredData || !selectedDateField || !selectedSumField) {
    setFilteredRows([]); // Clear filtered rows
    setFilteredFields([]); // Clear available fields
    return; // Skip if any necessary field is missing
  }

  const dateIndex = filteredData.headers.indexOf(selectedDateField);
  const sumIndex = filteredData.headers.indexOf(selectedSumField);

  // Filter rows where both date and sum fields are not empty
  const rowsWithValidFields = filteredData.rows.filter(
    row => row[dateIndex] !== "" && row[sumIndex] !== ""
  );

  if (rowsWithValidFields.length > 0) {
    setFilteredRows(rowsWithValidFields);

    // Create a new list of available fields from the rows with valid data
    const availableFields = filteredData.headers.filter(header => {
      const headerIndex = filteredData.headers.indexOf(header);
      return rowsWithValidFields.some(row => row[headerIndex] !== "");
    });

    // Match available fields with originalCatFields (categorical fields)
    const newFilteredFields = originalCatFields.filter(field => {
      return (
        availableFields.includes(field) &&
        field !== selectedDateField &&
        field !== selectedSumField
      );
    });

    // Update only with the valid available fields
    setFilteredFields(newFilteredFields);

    console.log('newfilteredfields: ', newFilteredFields)

    // Remove any selected field that is no longer in newFilteredFields
    setSelectedFields(prevSelectedFields =>
      prevSelectedFields.filter(field => newFilteredFields.includes(field))
    );

    // Remove topN values associated with removed fields
    setTopNValues(prevTopNValues =>
      prevTopNValues.filter((_, index) => newFilteredFields.includes(selectedFields[index]))
    );

  } else {
    setFilteredRows([]); // No rows match, clear the filtered rows
    setFilteredFields([]); // No matching fields, clear available fields
    setSelectedFields([]); // Clear selected fields
    setTopNValues([]);     // Clear top N values
  }
}, [filteredData, selectedDateField, selectedSumField, originalCatFields]);


  const consolidateSpreadsheets = async () => {
    try {
      let consolidatedHeaders = new Set(); // Use Set to handle unique headers
      let consolidatedRows = [];
      let allDateCols = new Set(); // Set to store all date columns
      let allNumCols = new Set();  // Set to store all numerical columns
      let allCatCols = new Set();  // Set to store all categorical columns
  
      for (const spreadsheetId of selectedSpreadsheets) {
        const spreadsheet = spreadsheets.find(s => s.id === spreadsheetId);
        const data = await fetchSpreadsheetData(spreadsheetId, spreadsheet, editedRows, true);
  
        // Add only date, numerical, and categorical columns to consolidated headers
        const relevantCols = [...data.dateCols, ...data.numCols, ...data.catCols];
        relevantCols.forEach(col => consolidatedHeaders.add(col));
  
        data.dateCols.forEach(col => allDateCols.add(col));
        data.numCols.forEach(col => allNumCols.add(col));
        data.catCols.forEach(col => allCatCols.add(col));
  
        // Map the row data according to the consolidated headers
        const rowData = data.processedRows.map(row => {
          const rowObject = {};
          
          // Map each relevant column in the spreadsheet to its respective header in the rowObject
          data.filteredHeaders.forEach((header, index) => {
            if (relevantCols.includes(header)) {
              rowObject[header] = row[index] || '';
            }
          });
  
          return rowObject;
        });
  
        // Append the processed rows to the consolidatedRows array
        consolidatedRows = consolidatedRows.concat(rowData);
      }
  
      // Convert Set to Array for headers
      const consolidatedHeadersArray = Array.from(consolidatedHeaders);
  
      // Ensure that all rows have the same header structure
      const finalRows = consolidatedRows.map(row => {
        const formattedRow = {};
        
        // Ensure every row includes all consolidated headers with empty strings for missing columns
        consolidatedHeadersArray.forEach(header => {
          formattedRow[header] = row[header] || ''; // Fill in missing columns with empty string
        });
  
        return consolidatedHeadersArray.map(header => formattedRow[header]);
      });
  
      console.log('consolidatedHeadersArray: ', consolidatedHeadersArray);
      console.log('finalRows: ', finalRows);
  
      // Set the consolidated data into filteredData state
      setFilteredData({
        headers: consolidatedHeadersArray,
        rows: finalRows,
      });
  
      // Set the columns for Date, Sum (Numerical), and Categorical fields using the consolidated sets
      setDateColumns(Array.from(allDateCols));
      setSumFields(Array.from(allNumCols));
      setOriginalCatFields(Array.from(allCatCols)); // Store original categorical fields
  
      // Set the default selectedDateField if not already set
      if (!selectedDateField && allDateCols.size > 0) {
        setSelectedDateField(Array.from(allDateCols)[0]);
      }
  
      if (!selectedSumField && allNumCols.size > 0) {
        setSelectedSumField(Array.from(allNumCols)[0]);
      }
  
    } catch (error) {
      console.error('Error consolidating spreadsheets:', error);
    }
  };

  const handleSingleSpreadsheetSelection = (spreadsheetId) => {
    const selectedSpreadsheet = spreadsheets.find(spreadsheet => spreadsheet.id === spreadsheetId);
    setCurrentSpreadsheet(selectedSpreadsheet);
    setSelectedFields([]);  // Reset selected fields
    setTopNValues([]);      // Reset Top N values
    setSelectedDateField('');  // Reset selected date field
    setSelectedSumField('');   // Reset selected sum field
    setFilteredData(null);  // Clear filtered data when a new spreadsheet is selected
  };
  

  const handleCheckboxChange = (field) => {
    setSelectedFields((prevSelectedFields) => {
      const isSelected = prevSelectedFields.includes(field);
      const updatedFields = isSelected
        ? prevSelectedFields.filter((item) => item !== field)
        : [...prevSelectedFields, field];
  
      // Add or remove associated Top N values
      if (!isSelected) {
        setTopNValues((prevTopNValues) => [...prevTopNValues, '']);
      } else {
        setTopNValues((prevTopNValues) =>
          prevTopNValues.filter((_, index) => index !== prevSelectedFields.indexOf(field))
        );
      }
  
      return updatedFields;
    });
  };  

  const moveField = (fromIndex, toIndex) => {
    setSelectedFields((prevSelectedFields) => {
      const updatedFields = [...prevSelectedFields];
      const [movedField] = updatedFields.splice(fromIndex, 1);
      updatedFields.splice(toIndex, 0, movedField);
      return updatedFields;
    });

    setTopNValues((prevTopNValues) => {
      const updatedValues = [...prevTopNValues];
      const [movedValue] = updatedValues.splice(fromIndex, 1);
      updatedValues.splice(toIndex, 0, movedValue);
      return updatedValues;
    });
  };

  const handleRemoveField = (fieldToRemove) => {
    // Remove the field from selected fields and top N values
    setSelectedFields(prevSelectedFields => prevSelectedFields.filter(field => field !== fieldToRemove));
    setTopNValues(prevTopNValues => prevTopNValues.filter((_, index) => selectedFields[index] !== fieldToRemove));
  
    // Also uncheck the associated checkbox
    setFilteredFields(prevFilteredFields => {
      const updatedFilteredFields = prevFilteredFields.includes(fieldToRemove)
        ? prevFilteredFields.filter(field => field !== fieldToRemove)
        : prevFilteredFields;
      return updatedFilteredFields;
    });
  };
  

  const handleTopNChange = (index, value) => {
    setTopNValues((prevTopNValues) => {
      const updatedValues = [...prevTopNValues];
      updatedValues[index] = value;
      return updatedValues;
    });
  };

  const handleSumFieldChange = (event) => {
    setSelectedSumField(event.target.value);
  };

  const handleDateFieldChange = (event) => {
    setSelectedDateField(event.target.value);
  };

  const handleGroupingOptionChange = (event) => {
    setGroupingOption(event.target.value);
  };

  const handleFromDateChange = (event) => {
    const newFromDate = event.target.value;
    setFromDate(newFromDate);

    // Ensure that fromDate is not after toDate
    if (newFromDate > toDate) {
      setToDate(newFromDate);
    }
  };

  const handleToDateChange = (event) => {
    const newToDate = event.target.value;
    setToDate(newToDate);

    // Ensure that toDate is not before fromDate
    if (newToDate < fromDate) {
      setFromDate(newToDate);
    }
  };

   // Function to toggle suborg expansion
   const toggleSuborgExpand = (suborg) => {
    setExpandedSuborgs((prevExpanded) => ({
      ...prevExpanded,
      [suborg]: !prevExpanded[suborg],
    }));
  };

  // Function to handle checkbox change for selecting spreadsheets
  const handleSpreadsheetSelection = (spreadsheetId) => {
    setSelectedSpreadsheets((prevSelected) => {
      const updatedSelected = prevSelected.includes(spreadsheetId)
        ? prevSelected.filter((id) => id !== spreadsheetId) // Uncheck if already selected
        : [...prevSelected, spreadsheetId]; // Add if not already selected
  
      // If no spreadsheets are selected, reset all fields and states
      if (updatedSelected.length === 0) {
        resetConsolidationFields(); // Reset when no spreadsheets are selected
      } else {
        consolidateSpreadsheets(); // Run consolidation logic
      }
  
      return updatedSelected;
    });
  };

  const resetConsolidationFields = () => {
    setSelectedSpreadsheets([]);
    setSelectedFields([]);
    setTopNValues([]);
    setSelectedDateField('');
    setSelectedSumField('');
    setFilteredData(null);
    setDateColumns([]);
    setSumFields([]);
    setFilteredRows([]);
    setFilteredFields([]);
    setFromDate('');
    setToDate('');
  };
  

  // Group spreadsheets by suborg
  const groupedSpreadsheets = spreadsheets.reduce((groups, spreadsheet) => {
    const suborg = spreadsheet.suborganisation || 'No Suborganisation';
    if (!groups[suborg]) {
      groups[suborg] = [];
    }
    groups[suborg].push(spreadsheet);
    return groups;
  }, {});

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {/* Mode Selection Dropdown */}
        <div>
          <label>Do you want to use data from a single spreadsheet or consolidate multiple sheets?</label>
          <select value={analysisMode} onChange={handleAnalysisModeChange}>
            <option value="single">Single Spreadsheet</option>
            <option value="consolidation">Consolidation</option>
          </select>
        </div>

        {/* Conditional Rendering Based on Mode */}
        {analysisMode === 'single' ? (
  <div>
    <h3>Select Spreadsheet</h3>
    {Object.keys(groupedSpreadsheets).map((suborg) => (
      <div key={suborg}>
        <div onClick={() => toggleSuborgExpand(suborg)} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
          {expandedSuborgs[suborg] ? '▼' : '▶'} {suborg}
        </div>
        {expandedSuborgs[suborg] && (
          <div style={{ marginLeft: '20px' }}>
            {groupedSpreadsheets[suborg].map((spreadsheet) => (
              <div key={spreadsheet.id}>
                <label>
                  <input
                    type="radio"  // Change input type to radio for single selection
                    checked={currentSpreadsheet?.id === spreadsheet.id}  // Check if the current spreadsheet matches
                    onChange={() => handleSingleSpreadsheetSelection(spreadsheet.id)}  // Call the updated handler
                  />
                  {spreadsheet.newName}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
        ) : (
          <div>
            <h3>Select Spreadsheets to Consolidate</h3>
            {Object.keys(groupedSpreadsheets).map((suborg) => (
              <div key={suborg}>
                <div onClick={() => toggleSuborgExpand(suborg)} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                  {expandedSuborgs[suborg] ? '▼' : '▶'} {suborg}
                </div>
                {expandedSuborgs[suborg] && (
                  <div style={{ marginLeft: '20px' }}>
                    {groupedSpreadsheets[suborg].map((spreadsheet) => (
                      <div key={spreadsheet.id}>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedSpreadsheets.includes(spreadsheet.id)}
                            onChange={() => handleSpreadsheetSelection(spreadsheet.id)}
                          />
                          {spreadsheet.newName}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div>
          <h3>Other Fields</h3>
          <label>Select Date Field: </label>
          <select value={selectedDateField} onChange={handleDateFieldChange}>
            {dateColumns.map((field, index) => (
              <option key={index} value={field}>
                {field}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>From Date: </label>
          <input 
            type="date" 
            value={fromDate} 
            onChange={handleFromDateChange}
          />
        </div>
        <div>
          <label>To Date: </label>
          <input 
            type="date" 
            value={toDate} 
            onChange={handleToDateChange} 
          />
        </div>
        <div>
          <label>Select Grouping Option: </label>
          <select value={groupingOption} onChange={handleGroupingOptionChange}>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
          </select>
        </div>
        <div>
          <label>Select Sum Field: </label>
          <select value={selectedSumField} onChange={handleSumFieldChange}>
            {sumFields.map((field, index) => (
              <option key={index} value={field}>
                {field}
              </option>
            ))}
          </select>
        </div>
        <div className="field-selection">
          <div className="field-list">
            <h3>Available Fields</h3>
            {filteredFields.length > 0 ? (
            filteredFields.map((field, index) => (
              <div key={index} className="field-item">
                <input
                  type="checkbox"
                  checked={selectedFields.includes(field)}
                  onChange={() => handleCheckboxChange(field)}
                />
                <label>{field}</label>
              </div>
            ))
          ) : (
            <div>No data matching this date field and sum field combination</div>
          )}
          </div>
          <div className="field-config">
            <h3>Selected Fields</h3>
            <div className="field-container">
              {selectedFields.map((field, index) => (
                <DraggableField
                  key={field}
                  field={field}
                  index={index}
                  moveField={moveField}
                  handleTopNChange={handleTopNChange}
                  topN={topNValues[index]}
                  handleRemoveField={handleRemoveField} // Pass the remove handler
                />
              ))}
            </div>
          </div>
        </div>
        {filteredData && filteredRows.length > 0 ? (
          <div>
            <CustomPivotTable
              selectedFields={selectedFields}
              filteredData={filteredData.headers ? { headers: filteredData.headers, rows: filteredRows } : null}
              dateField={selectedDateField}
              sumField={selectedSumField}
              groupingOption={groupingOption}
              topNValues={topNValues}
            />
            {console.log('Rendering Pivot Table with:', {
              filteredDataheaders: filteredData?.headers,
              filteredRows,
              selectedFields,
              dateField: selectedDateField,
              sumField: selectedSumField,
              groupingOption,
              topNValues,
            })}
          </div>
        ) : currentSpreadsheet && selectedDateField && filteredData ? (
          <div>No data available for the selected date range.</div>
        ) : null}
      </div>
    </DndProvider>
  );
}

export default DataAnalysisPage;
    