import React, { useState, useEffect } from 'react';
import { useAuth } from '../authcontext';
import AddRowForm from './addrow';
import Popup from './popup';
import ModelRenderer from './modelrenderer';
import api from '../api'; // Assuming you have an API utility for making requests
import { useParams, useNavigate } from 'react-router-dom'; 
import PivotTableForm from './pivottableform';

const SupportingPage = () => {
    const { modelId } = useParams(); // Get the modelId from the URL
    const { currentModel, setCurrentModel, spreadsheets, models, isLoading: isModelsLoading } = useAuth();
    const [tableHeaders, setTableHeaders] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [showRowForm, setShowRowForm] = useState(false); // Controls simplified row form
    const [showDetailedRowForm, setShowDetailedRowForm] = useState(false); // Controls detailed row form
    const [showPivotTableForm, setShowPivotTableForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null); // State to hold the clicked row data
    const [detailedRowName, setDetailedRowName] = useState(''); // State for the custom row name
    const navigate = useNavigate();

    // Fetch the model whenever the modelId changes and models are loaded
    useEffect(() => {
        // Fetch the model only if modelId is present and models have been loaded
        if (modelId && !isModelsLoading && models.length > 0) {
            fetchModel(modelId);
        } else if (!isModelsLoading && models.length === 0) {
            // Optionally fetch the models again if not loaded (depending on context)
            console.warn('No models available.');
        }
    }, [modelId, isModelsLoading, models]);
       

    const fetchModel = async (modelId) => {
        try {
            setIsLoading(true);
            const model = models.find((m) => m.id === parseInt(modelId, 10));

            if (model) {
                setCurrentModel(model); // Set the new model in context
                const histHeaders = getHeadersByBasis(model, 'Hist');
                const foreHeaders = getHeadersByBasis(model, 'Fore');

                const headers = ['Row name', 'Driver', ...histHeaders.slice(-model.histPeriods), ...foreHeaders.slice(0, model.forePeriods)];
                setTableHeaders(headers);

                processRowsData(model, model.histPeriods, model.forePeriods);
            } else {
                console.error('Model not found for modelId:', modelId);
            }
        } catch (error) {
            console.error('Error fetching model:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getHeadersByBasis = (model, type) => {
        let headers = [];
        if (!model) {
            console.error('Model is undefined');
            return headers;
        }
    
        try {
            let rawHeaders;
            switch (model.basis) {
                case 'Yearly':
                    rawHeaders = type === 'Hist' ? model.annualHistHeaders : model.annualForeHeaders;
                    break;
                case 'Quarterly':
                    rawHeaders = type === 'Hist' ? model.quarterlyHistHeaders : model.quarterlyForeHeaders;
                    break;
                case 'Monthly':
                    rawHeaders = type === 'Hist' ? model.monthlyHistHeaders : model.monthlyForeHeaders;
                    break;
                default:
                    console.error('Invalid basis specified in the model');
                    return [];
            }
    
            headers = JSON.parse(rawHeaders);
    
            if (!Array.isArray(headers)) {
                console.error('Headers are not an array:', headers);
                return [];
            }
    
            return headers.map(header => Array.isArray(header) ? header[0] : header.label || '');
        } catch (error) {
            console.error('Error parsing headers:', error);
            return [];
        }
    };

    const processRowsData = async (model, histPeriodsCount, forePeriodsCount) => {
        if (!model) {
            console.error('Model is undefined');
            setRowsData([]);
            return;
        }
    
        setIsLoading(true); 
        const rows = [];
    
        const {
            rowNames = [],
            drivers = [],
            annualHistRows = [],
            quarterlyHistRows = [],
            monthlyHistRows = [],
            annualForeRows = [],
            quarterlyForeRows = [],
            monthlyForeRows = [],
        } = model;
    
        if (!Array.isArray(rowNames) || rowNames.length === 0) {
            console.warn('No rows found in the model');
            setRowsData([]); 
            setIsLoading(false); 
            return;
        }
    
        let histRowsData, foreRowsData;
        switch (model.basis) {
            case 'Yearly':
                histRowsData = JSON.parse(annualHistRows);
                foreRowsData = JSON.parse(annualForeRows);
                break;
            case 'Quarterly':
                histRowsData = JSON.parse(quarterlyHistRows);
                foreRowsData = JSON.parse(quarterlyForeRows);
                break;
            case 'Monthly':
                histRowsData = JSON.parse(monthlyHistRows);
                foreRowsData = JSON.parse(monthlyForeRows);
                break;
            default:
                console.error('Invalid basis specified in the model');
                setRowsData([]);
                setIsLoading(false);
                return;
        }
    
        for (let i = 0; i < rowNames.length; i++) {
            const rowData = {
                name: rowNames[i] || '',
                driver: drivers[i] || '',
                values: [],
            };
    
            if (histRowsData && Array.isArray(histRowsData[i])) {
                const histValues = histRowsData[i].slice(-histPeriodsCount);
                rowData.values.push(...histValues);
            } else {
                console.warn(`No historical data for row ${i + 1}`);
            }
    
            if (foreRowsData && Array.isArray(foreRowsData[i])) {
                if (drivers[i] === 'Percent') {
                    let lastHistoricalValue = parseFloat(rowData.values[rowData.values.length - 1]) || 0;
                    for (let j = 0; j < forePeriodsCount; j++) {
                        const percentIncrease = parseFloat(foreRowsData[i][j]) || 0;
                        if (isNaN(lastHistoricalValue) || isNaN(percentIncrease)) {
                            console.warn(`Invalid values for row ${i + 1}: lastHistoricalValue=${lastHistoricalValue}, percentIncrease=${percentIncrease}`);
                            rowData.values.push('N/A');
                        } else {
                            const newForecastValue = lastHistoricalValue + (lastHistoricalValue * percentIncrease / 100);
                            rowData.values.push(newForecastValue.toFixed(2));
                            lastHistoricalValue = newForecastValue;
                        }
                    }
                } else {
                    const foreValues = foreRowsData[i].slice(0, forePeriodsCount);
                    rowData.values.push(...foreValues);
                }
            } else {
                console.warn(`No forecasting data for row ${i + 1}`);
            }
    
            rows.push(rowData);
        }
    
        setRowsData(rows);
        setIsLoading(false);
    };      

    const handleRowClick = (row, rowIndex) => {
        // Ensure the supportingAssumptionModelIds is parsed correctly as JSON
        let supportingAssumptionModelIds = [];
    
        try {
            supportingAssumptionModelIds = JSON.parse(currentModel.supportingAssumptionModelIds);
        } catch (error) {
            console.error('Error parsing supportingAssumptionModelIds:', error);
        }
    
        // Check if the driver is "Supporting Assumptions"
        if (row.driver === 'Supporting Assumptions') {
            const supportingAssumptionId = supportingAssumptionModelIds?.[rowIndex]; // Safely access the ID using rowIndex
            if (supportingAssumptionId) {
                // Correctly navigate to the supporting assumption model page with the ID
                navigate(`/forecasting/${supportingAssumptionId}/sa`);
            } else {
                console.warn('No supporting assumption ID found for this row');
            }
        } else {
            // Proceed with showing the popup for other drivers
            setSelectedRowData(row);
        }
    };

    const handleValueChange = (index, newValue) => {
        const updatedRowData = { ...selectedRowData };
        updatedRowData.values[index] = newValue;
        setSelectedRowData(updatedRowData); // Update the selected row data
    };

    const closePopup = () => {
        setSelectedRowData(null); // Close the popup
    };

    const handleRowAdded = async (updatedModel) => {
        // Hide the row forms
        setShowRowForm(false);
        setShowDetailedRowForm(false);
    
        if (updatedModel) {
            try {
                // Update the context with the new model returned from AddRowForm
                console.log('updatedmodel: ', updatedModel)
                setCurrentModel(updatedModel);
    
                // Call function to update original model with supporting assumptions
                await updateOriginalModelWithSupportingAssumptions(updatedModel);
            } catch (error) {
                console.error('Error updating row:', error);
            }
        }
    };    
    

    const updateOriginalModelWithSupportingAssumptions = async (updatedModel) => {
        try {
            // Find the original models associated with this supporting assumption

            const drivers = updatedModel.drivers || [];

            const relevantModels = models.map((model) => {
                let parsedSupportingIds = [];
    
                // Parse supportingAssumptionModelIds if necessary
                try {
                    parsedSupportingIds = Array.isArray(model.supportingAssumptionModelIds) 
                                          ? model.supportingAssumptionModelIds 
                                          : JSON.parse(model.supportingAssumptionModelIds || '[]');
                } catch (error) {
                    console.error('Error parsing supportingAssumptionModelIds:', error);
                }
    
                // Find the index where the updatedModel.id matches
                const rowIndex = parsedSupportingIds.findIndex(id => id === String(updatedModel.id));

                console.log('modelid: ', model.id)
                console.log('rowindex: ', rowIndex)
    
                if (rowIndex !== -1) {
                    return {
                        modelId: model.id,
                        rowIndex // Store the index where the matching supporting assumption model ID was found
                    };
                }
                return null;
            }).filter(item => item !== null); // Filter out any null results
    
            console.log('Relevant models:', relevantModels);
    
            if (relevantModels.length === 0) {
                console.warn('No matching models found for this supporting assumption ID.');
                return;
            }

            console.log('annualhistrows: ', updatedModel.annualHistRows)
            console.log('annualforerows: ', updatedModel.annualForeRows)
    
            // Extract historical and forecast rows from the updated model
            const annualHistRows = updatedModel.annualHistRows;
            const annualForeRows = updatedModel.annualForeRows;
            const quarterlyHistRows = updatedModel.quarterlyHistRows;
            const quarterlyForeRows = updatedModel.quarterlyForeRows;
            const monthlyHistRows = updatedModel.monthlyHistRows;
            const monthlyForeRows = updatedModel.monthlyForeRows;
    
            // Function to recalculate forecast values based on percentages
            const recalculateForecastValues = (histRow, foreRow) => {
                let lastHistoricalValue = parseFloat(histRow[histRow.length - 1]) || 0;
                return foreRow.map((percent, index) => {
                    if (percent === "") return 0; // Set empty forecast values to 0
                    const percentIncrease = parseFloat(percent) || 0;
                    lastHistoricalValue += (lastHistoricalValue * percentIncrease) / 100;
                    return lastHistoricalValue;
                });
            };

            // Function to handle fixed amounts or supporting assumptions (returns stored values)
            const recalculateStoredValues = (foreRow) => {
                return foreRow.map(value => value === "" ? 0 : parseFloat(value) || 0); // Set empty values to 0
            };
    
            // Adjust the rows for each basis (Yearly, Quarterly, Monthly) based on driver type
            const recalculateAndSumRows = (histRows, foreRows, driver) => {
                return histRows.map((histRow, index) => {
                    const foreRow = foreRows[index] || [];
                    if (driver === 'Percent') {
                        return recalculateForecastValues(histRow, foreRow);  // Apply percentage increase
                    }
                    return recalculateStoredValues(foreRow);  // Fixed Amount or Supporting Assumption
                });
            };
    
            // Recalculate forecast rows based on the driver type from the drivers array
            const recalculatedAnnualForeRows = annualHistRows.map((histRow, index) => {
                const foreRow = annualForeRows[index] || [];
                const driverType = drivers[index] || 'Fixed Amount'; // Get driver type per row
                return recalculateAndSumRows([histRow], [foreRow], driverType)[0];
            });
    
            const recalculatedQuarterlyForeRows = quarterlyHistRows.map((histRow, index) => {
                const foreRow = quarterlyForeRows[index] || [];
                const driverType = drivers[index] || 'Fixed Amount'; // Get driver type per row
                return recalculateAndSumRows([histRow], [foreRow], driverType)[0];
            });
    
            const recalculatedMonthlyForeRows = monthlyHistRows.map((histRow, index) => {
                const foreRow = monthlyForeRows[index] || [];
                const driverType = drivers[index] || 'Fixed Amount'; // Get driver type per row
                return recalculateAndSumRows([histRow], [foreRow], driverType)[0];
            });
    
            // Sum the historical and forecast rows
            const summedHistRows = sumRows(annualHistRows);
            const summedForeRows = sumRows(recalculatedAnnualForeRows);
            const summedQuarterlyHistRows = sumRows(quarterlyHistRows);
            const summedQuarterlyForeRows = sumRows(recalculatedQuarterlyForeRows);
            const summedMonthlyHistRows = sumRows(monthlyHistRows);
            const summedMonthlyForeRows = sumRows(recalculatedMonthlyForeRows);

            console.log('Summed forecast rows:', summedForeRows);
            console.log('Summed historical rows:', summedHistRows);
            console.log('Summed forecast rows:', summedQuarterlyForeRows);
            console.log('Summed historical rows:', summedQuarterlyHistRows);
            console.log('Summed forecast rows:', summedMonthlyForeRows);
            console.log('Summed historical rows:', summedMonthlyHistRows);

            // Format rows: Convert 0 to an empty string, and wrap non-zero numbers in quotes
            const formatRowData = (rows) => {
                return rows.map(value => (value === 0 ? "" : `${value}`));
            };
    
            const formattedHistRows = formatRowData(summedHistRows);
            const formattedForeRows = formatRowData(summedForeRows);
            const formattedQuarterlyHistRows = formatRowData(summedQuarterlyHistRows);
            const formattedQuarterlyForeRows = formatRowData(summedQuarterlyForeRows);
            const formattedMonthlyHistRows = formatRowData(summedMonthlyHistRows);
            const formattedMonthlyForeRows = formatRowData(summedMonthlyForeRows);
    
            // Prepare data to be sent to the parent models
            const rowData = {
                annualHistRows: formattedHistRows,
                quarterlyHistRows: formattedQuarterlyHistRows,
                monthlyHistRows: formattedMonthlyHistRows,
                annualForeRows: formattedForeRows,
                quarterlyForeRows: formattedQuarterlyForeRows,
                monthlyForeRows: formattedMonthlyForeRows
            };

            console.log('rowdata: ', rowData)
    
            // Make an API call to update the parent model rows for each relevant model
            for (const { modelId, rowIndex } of relevantModels) {
                try {
                    await api.put(`/models/${modelId}/update-parent-row`, { ...rowData, rowIndex }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log(`Successfully updated parent model with ID: ${modelId}`);
                } catch (error) {
                    console.error(`Error updating parent model with ID ${modelId}:`, error);
                }
            }

            window.location.reload()
        } catch (error) {
            console.error('Error updating original models with supporting assumptions:', error);
        }
    };    
    
    // Utility function to sum rows with better validation
    const sumRows = (rows) => {
        if (!Array.isArray(rows) || rows.length === 0) {
            console.warn('Rows is not a valid array or is empty.');
            return []; // Return an empty array if rows is not valid
        }

        // Initialize an array to hold the summed values for each column (historical periods)
        const numberOfColumns = rows[0].length; // Assume all rows have the same number of columns
        const summedValues = Array(numberOfColumns).fill(0); // Initialize an array with zeros

        // Ensure we are dealing with arrays that contain valid numbers and include the most recent row
        rows.forEach((row) => {
            if (Array.isArray(row) && row.length > 0) {
                row.forEach((value, index) => {
                    const rowValue = parseFloat(value); // Parse the row value as a float
                    if (!isNaN(rowValue)) { // Only add valid numbers
                        summedValues[index] += rowValue;
                    }
                });
            } else {
                console.warn('Encountered non-array row or empty row:', row);
            }
        });

        return summedValues;
    };   
    
    // API Call to create a new detailed model
    const createDetailedModel = async () => {
        try {
            const response = await api.post('/models', {
                organisationId: currentModel.organisationId,
                superModelId: currentModel.superModelId, // This links the new model to the current model
                modelName: detailedRowName,
                modelType: 'Supporting Assumptions',
                basis: currentModel.basis,
                histPeriods: currentModel.histPeriods,
                forePeriods: currentModel.forePeriods,
                yearEnd: currentModel.yearEnd,
                templateUrl: currentModel.templateUrl,
                annualHistHeaders: currentModel.annualHistHeaders,
                quarterlyHistHeaders: currentModel.quarterlyHistHeaders,
                monthlyHistHeaders: currentModel.monthlyHistHeaders,
                annualForeHeaders: currentModel.annualForeHeaders,
                quarterlyForeHeaders: currentModel.quarterlyForeHeaders,
                monthlyForeHeaders: currentModel.monthlyForeHeaders,
            });
            if (response.status === 201) {
                console.log('Detailed model created successfully');

                const newSupportingAssumptionId = response.data.model.id;

                // Add a new row to the current model with padding and the supporting assumption ID
                await addDetailedRowToCurrentModel(newSupportingAssumptionId);
                handleRowAdded(); // Optionally reload the rows after creation
                navigate(`/forecasting/${newSupportingAssumptionId}/sa`);
                window.location.reload()
            } else {
                console.error('Failed to create detailed model');
            }
        } catch (error) {
            console.error('Error creating detailed model:', error);
        }
    };     

    const addDetailedRowToCurrentModel = async (supportingAssumptionId) => {
        try {
            // Padding for historical and forecast data (10 for annual, 40 for quarterly, 120 for monthly)
            const emptyAnnualHistData = Array(10).fill('');
            const emptyQuarterlyHistData = Array(40).fill('');
            const emptyMonthlyHistData = Array(120).fill('');
            const emptyAnnualForeData = Array(10).fill('');
            const emptyQuarterlyForeData = Array(40).fill('');
            const emptyMonthlyForeData = Array(120).fill('');
    
            // Prepare the row data with padding
            const rowData = {
                rowNames: [detailedRowName], // Use the entered detailed row name
                rowVariables: ['None'],
                categories: ['None'],
                groupedOver: 'None',
                dateColumn: 'None',
                sourceFile: ['None'],
                drivers: ['Supporting Assumptions'], // Set the driver to "Supporting Assumptions"
                supportingAssumptionModelIds: [String(supportingAssumptionId)], // Set the new supporting assumption model ID
                annualHistRows: [emptyAnnualHistData],
                quarterlyHistRows: [emptyQuarterlyHistData],
                monthlyHistRows: [emptyMonthlyHistData],
                annualForeRows: [emptyAnnualForeData],
                quarterlyForeRows: [emptyQuarterlyForeData],
                monthlyForeRows: [emptyMonthlyForeData],
            };
    
            // Send the new row to the backend to add it to the current model
            const response = await api.put(`/models/${currentModel.id}/add-row`, rowData);
    
            if (response.status === 200) {
                console.log('Detailed row added successfully');
            } else {
                console.error('Failed to add detailed row to model:', response.data);
            }
        } catch (error) {
            console.error('Error adding detailed row:', error);
        }
    };   

    const handleSave = async (updatedData) => {
        try {
            const { rowIndex, updatedValues, basis } = updatedData;
    
            console.log("Sending updatedValues to backend: ", updatedValues);
    
            const response = await api.put(`/model/update-forecasting-row/${currentModel.id}`, {
                rowIndex: rowIndex,
                updatedValues: updatedValues,
                basis: basis,
            });
    
            if (response.status === 200) {
                closePopup();
                window.location.reload()
            } else {
                console.error('Failed to save changes.');
            }
        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };   

    const toggleShowRowForm = () => {
        setShowRowForm(!showRowForm);
        setShowDetailedRowForm(false);
        setShowPivotTableForm(false);
    };

    const toggleShowDetailedRowForm = () => {
        setShowDetailedRowForm(!showDetailedRowForm);
        setShowRowForm(false); // Hide simplified row form when showing detailed form
        setShowPivotTableForm(false);
    };     

    const toggleShowPivotTableForm = () => {
        setShowPivotTableForm(!showPivotTableForm);
        setShowRowForm(false);
        setShowDetailedRowForm(false); // Ensure row forms are hidden when this is clicked
    };

    return (
        <div>
            {currentModel && (
                <>
                    <h2>{`${currentModel.modelName} - ${currentModel.modelType}`}</h2>
                    <div className="button-container">
                    <button onClick={toggleShowRowForm}>Add Simplified Row</button>
                    <button onClick={toggleShowDetailedRowForm}>Add Detailed Row</button>
                    <button onClick={toggleShowPivotTableForm}>Add Pivot Table</button>
                    </div>

                    {showRowForm && (
                        <AddRowForm 
                            currentModel={currentModel}
                            spreadsheets={spreadsheets}
                            onRowAdded={handleRowAdded}
                        />
                    )}

                    {showDetailedRowForm && (
                        <div>
                            <input 
                                type="text" 
                                placeholder="Enter detailed row name" 
                                value={detailedRowName}
                                onChange={(e) => setDetailedRowName(e.target.value)}
                            />
                            <button onClick={createDetailedModel}>
                                Confirm
                            </button>
                        </div>
                    )}

                    {showPivotTableForm && (
                        <PivotTableForm/>
                    )}

                    {isLoading ? (
                        <p>Loading data...</p>
                    ) : (
                        <div className="scrollable-table-container">
                            <ModelRenderer 
                                rowsData={rowsData} 
                                tableHeaders={tableHeaders} 
                                histPeriods={currentModel.histPeriods} 
                                forePeriods={currentModel.forePeriods} 
                            />
                        </div>
                    )}
                </>
            )}

            {/* Popup for editing row */}
            {selectedRowData && (
                <Popup 
                headers={tableHeaders} 
                rowData={selectedRowData} 
                forePeriods={currentModel.forePeriods}
                currentModel={currentModel}
                rowIndex={rowsData.indexOf(selectedRowData)}
                onClose={closePopup} 
                onValueChange={handleValueChange} 
                onSave={handleSave}
              />              
            )}
        </div>
    );
};

export default SupportingPage;
