import React, { useState, useEffect } from 'react';
import moment from 'moment';

function CustomPivotTable({ selectedFields, filteredData, dateField, sumField, groupingOption, topNValues, setTableDataCallback  }) {
  const [tableData, setTableData] = useState([]);
  const [visiblePeriods, setVisiblePeriods] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());

  useEffect(() => {
    if (!filteredData || !filteredData.rows || !filteredData.headers || !dateField || !sumField) {
      setTableData([]);
      setVisiblePeriods([]);
      return;
    }

    const { headers, rows } = filteredData;
    const { aggregatedData, uniquePeriods } = aggregateData(headers, rows, selectedFields, dateField, sumField, groupingOption);

    console.log('selectedfields: ', selectedFields)
    console.log('datefield: ', dateField)
    console.log('sumfield: ', sumField)
    console.log('groupingoption: ', groupingOption)
    console.log('topnvalues: ', topNValues)
    console.log('aggregated data: ', aggregatedData)
    console.log('unique periods: ', uniquePeriods)

    const restrictedData = applyTopN(aggregatedData, selectedFields, topNValues);

    console.log('restricteddata: ', restrictedData)

    // Only update if the aggregatedData has actually changed
    if (JSON.stringify(aggregatedData) !== JSON.stringify(tableData)) {
      setTableData(aggregatedData);
      setVisiblePeriods(uniquePeriods);

      // Pass both restrictedData and visiblePeriods back to the parent
      if (setTableDataCallback) {
        setTableDataCallback({ restrictedData, visiblePeriods: uniquePeriods });
      }
    }

  }, [filteredData, selectedFields, dateField, sumField, groupingOption, topNValues, setTableDataCallback]);

  const toggleRowExpansion = (rowKey) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(rowKey)) {
        newExpandedRows.delete(rowKey);
      } else {
        newExpandedRows.add(rowKey);
      }
      return newExpandedRows;
    });
  };

  const renderRows = (data, depth = 0) => {
    // Sort the keys based on the total sum in descending order, but ensure "Other" is always last
    const sortedKeys = Object.keys(data).sort((a, b) => {
      if (a === 'Other') return 1;
      if (b === 'Other') return -1;
  
      const sumA = Object.values(data[a].total).reduce((acc, period) => acc + period.sum, 0);
      const sumB = Object.values(data[b].total).reduce((acc, period) => acc + period.sum, 0);
      return sumB - sumA;
    });
  
    return sortedKeys.map((key) => {
      const row = data[key];
      const isBottomLevel = depth === selectedFields.length - 1;
      const isExpanded = expandedRows.has(key);
  
      return (
        <React.Fragment key={key}>
          <tr>
            <td style={{ paddingLeft: `${depth * 20}px` }} onClick={() => !isBottomLevel && toggleRowExpansion(key)}>
              {!isBottomLevel ? (isExpanded ? '▼' : '►') : ''} {key}
            </td>
            {visiblePeriods.map((period) => (
              <td key={period}>
                {row.total && row.total[period]
                  ? `${row.total[period].sum.toFixed(2)}`
                  : '0.00'}
              </td>
            ))}
          </tr>
          {!isBottomLevel && isExpanded && renderRows(row.children, depth + 1)}
        </React.Fragment>
      );
    });
  };  

  return (
    <div className="custom-pivot-table">
      <table className="data-table">
        <thead>
          <tr>
            <th>Category</th>
            {visiblePeriods.map((period, index) => (
              <th key={index}>{period}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderRows(tableData)}</tbody>
      </table>
    </div>
  );
}

const aggregateData = (headers, rows, selectedFields, dateField, sumField, groupingOption) => {
  const periodIndex = headers.indexOf(dateField);
  const sumFieldIndex = headers.indexOf(sumField);
  const categoryIndices = selectedFields.map((field) => headers.indexOf(field));

  const aggregatedData = {};
  const uniquePeriods = new Set();

  rows.forEach((row) => {
    const dateValue = row[periodIndex];
    const momentDate = moment(dateValue, "DD/MM/YYYY", true);

    if (!momentDate.isValid()) {
      console.error(`Invalid date format found: ${dateValue}`);
      return;
    }

    const period = groupingOption === "Monthly" ? `${momentDate.format("MM")}/${momentDate.format("YYYY")}` : momentDate.format("YYYY");

    const sumValue = parseFloat(row[sumFieldIndex]);

    uniquePeriods.add(period);

    let currentLevel = aggregatedData;
    categoryIndices.forEach((index, depth) => {
      const category = row[index];
      if (!currentLevel[category]) {
        currentLevel[category] = { total: {}, children: {} };
      }
      if (!currentLevel[category].total[period]) {
        currentLevel[category].total[period] = { sum: 0 };
      }
      if (depth === categoryIndices.length - 1) {
        currentLevel[category].total[period].sum += sumValue;
      }
      currentLevel = currentLevel[category].children;
    });
  });

  const sortedPeriods = Array.from(uniquePeriods).sort((a, b) => {
    if (groupingOption === "Monthly") {
      const [monthA, yearA] = a.split("/").map(Number);
      const [monthB, yearB] = b.split("/").map(Number);
      return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
    } else {
      return a - b; // Simple numeric sort for years
    }
  });

  propagateTotals(aggregatedData, sortedPeriods);

  return { aggregatedData, uniquePeriods: sortedPeriods };
};

const propagateTotals = (data, periods) => {
  const totals = {};

  for (const key in data) {
    if (data[key].children && Object.keys(data[key].children).length > 0) {
      const subTotals = propagateTotals(data[key].children, periods);

      for (const period in subTotals) {
        if (!data[key].total[period]) {
          data[key].total[period] = { sum: 0 };
        }
        data[key].total[period].sum += subTotals[period].sum;
      }
    }
  }

  for (const key in data) {
    if (data[key].total) {
      for (const period of periods) {
        if (data[key].total[period]) {
          if (!totals[period]) {
            totals[period] = { sum: 0 };
          }
          totals[period].sum += data[key].total[period].sum;
        }
      }
    }
  }

  return totals;
};

const applyTopN = (data, selectedFields, topNValues, depth = 0) => {
  if (depth >= selectedFields.length) return data;

  const topN = parseInt(topNValues[depth], 10);
  if (isNaN(topN) || topN < 1) {
    // No top N restriction, return the data as is
    return data;
  }

  const sortedKeys = Object.keys(data).sort((a, b) => {
    const sumA = Object.values(data[a].total).reduce((acc, period) => acc + period.sum, 0);
    const sumB = Object.values(data[b].total).reduce((acc, period) => acc + period.sum, 0);
    return sumB - sumA;
  });

  const limitedData = {};
  let otherTotal = {};

  sortedKeys.slice(0, topN).forEach(key => {
    limitedData[key] = data[key];
  });

  sortedKeys.slice(topN).forEach(key => {
    if (!limitedData['Other']) {
      limitedData['Other'] = { total: {}, children: {} };
    }

    for (const period in data[key].total) {
      if (!limitedData['Other'].total[period]) {
        limitedData['Other'].total[period] = { sum: 0 };
      }
      limitedData['Other'].total[period].sum += data[key].total[period].sum;
    }

    for (const childKey in data[key].children) {
      if (!limitedData['Other'].children[childKey]) {
        limitedData['Other'].children[childKey] = data[key].children[childKey];
      } else {
        for (const period in data[key].children[childKey].total) {
          if (!limitedData['Other'].children[childKey].total[period]) {
            limitedData['Other'].children[childKey].total[period] = { sum: 0 };
          }
          limitedData['Other'].children[childKey].total[period].sum += data[key].children[childKey].total[period].sum;
        }
      }
    }
  });

  // Apply topN restriction recursively to children
  for (const key in limitedData) {
    if (limitedData[key].children) {
      limitedData[key].children = applyTopN(limitedData[key].children, selectedFields, topNValues, depth + 1);
    }
  }

  // Ensure "Other" is always at the bottom
  if (limitedData['Other']) {
    const other = limitedData['Other'];
    delete limitedData['Other'];
    limitedData['Other'] = other;
  }

  return limitedData;
};

export default CustomPivotTable;
