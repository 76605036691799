import React, { useState, useEffect } from 'react';
import moment from 'moment';
import api from '../api';
import SpreadsheetSelector from './spreadsheetselector';
import fetchSpreadsheetData from '../fetchspreadsheetdata';
import * as XLSX from 'xlsx';
import { calculatePeriods } from './calculateperiods'; // Import the calculatePeriods function
import { useAuth } from '../authcontext'; // Import the useAuth hook

const AddRowForm = ({ currentModel, spreadsheets, onRowAdded }) => {
  const [isHistorical, setIsHistorical] = useState(true);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState('');
  const [processedData, setProcessedData] = useState(null);
  const [spreadsheetData, setSpreadsheetData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedVariable, setSelectedVariable] = useState('');
  const [selectedDateColumn, setSelectedDateColumn] = useState('');
  const [categoryValues, setCategoryValues] = useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('Fixed Amount');
  const [rowName, setRowName] = useState('');
  const [forecastInputs, setForecastInputs] = useState({});
  const [forecastAmounts, setForecastAmounts] = useState({}); 
  const [historicalAmounts, setHistoricalAmounts] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const { editedRows } = useAuth(); // Get editedRows from useAuth

  const loadSpreadsheetData = async (spreadsheetId) => {
    const currentSpreadsheet = spreadsheets.find((s) => s.id === spreadsheetId);
    const data = await fetchSpreadsheetData(spreadsheetId, currentSpreadsheet, editedRows, true, true);
    setProcessedData(data);
  };

  useEffect(() => {
    if (selectedCategory && spreadsheetData) {
      const uniqueValues = [
        ...new Set(spreadsheetData.map((row) => row[selectedCategory])),
      ];
      console.log('Unique Category Values:', uniqueValues);
      setCategoryValues(uniqueValues);
      setSelectedCategoryValue(''); // Reset category value when category changes
    }
  }, [selectedCategory, spreadsheetData]);

  useEffect(() => {
    console.log('Selected Spreadsheet:', selectedSpreadsheet);
    console.log('Selected Category:', selectedCategory);
    console.log('Selected Variable:', selectedVariable);
    console.log('Selected Date Column:', selectedDateColumn);
    console.log('Selected Category Value:', selectedCategoryValue);
  }, [selectedSpreadsheet, selectedCategory, selectedVariable, selectedDateColumn, selectedCategoryValue]);

  const parseExcelDate = (excelDate) => {
    if (typeof excelDate === 'number') {
      const date = XLSX.SSF.parse_date_code(excelDate);
      if (date) {
        return moment({ y: date.y, M: date.m - 1, d: date.d });
      }
    } else if (typeof excelDate === 'string') {
      const parsedDate = moment(excelDate, ["DD/MM/YYYY", "YYYY-MM-DD"]);
      if (parsedDate.isValid()) {
        return parsedDate;
      }
    }
    return moment.invalid(); // Return an invalid moment if parsing fails
  };

  const handleImportRowData = async () => {
    if (!rowName || !selectedDriver) {
        console.error('Row name and driver must be filled.');
        return;
    }

    // Calculate historical periods for all bases (yearly, quarterly, monthly)
    const annualPeriods = calculatePeriods('Yearly', currentModel.currentDate, 10, 0, currentModel.yearEnd);
    const quarterlyPeriods = calculatePeriods('Quarterly', currentModel.currentDate, 40, 0, currentModel.yearEnd);
    const monthlyPeriods = calculatePeriods('Monthly', currentModel.currentDate, 120, 0, currentModel.yearEnd);

    // Calculate forecast periods based on the current model's basis
    const forecastPeriods = calculatePeriods(
        currentModel.basis,
        currentModel.currentDate,
        0,
        currentModel.forePeriods,
        currentModel.yearEnd
    );

    // Prepare historical data by mapping only the sum values, converting them to strings and replacing zeroes with empty strings
    const annualHistData = annualPeriods.map(period => {
        const value = calculateSumForPeriod(period.startDate, period.endDate, processedData);
        return value === 0 ? '' : String(value);
    });

    const quarterlyHistData = quarterlyPeriods.map(period => {
        const value = calculateSumForPeriod(period.startDate, period.endDate, processedData);
        return value === 0 ? '' : String(value);
    });

    const monthlyHistData = monthlyPeriods.map(period => {
        const value = calculateSumForPeriod(period.startDate, period.endDate, processedData);
        return value === 0 ? '' : String(value);
    });

    // Group historical data into sub-arrays of 10, 40, and 120 for annual, quarterly, and monthly respectively
    const groupedAnnualHistData = [];
    for (let i = 0; i < annualHistData.length; i += 10) {
        groupedAnnualHistData.push(annualHistData.slice(i, i + 10));
    }

    const groupedQuarterlyHistData = [];
    for (let i = 0; i < quarterlyHistData.length; i += 40) {
        groupedQuarterlyHistData.push(quarterlyHistData.slice(i, i + 40));
    }

    const groupedMonthlyHistData = [];
    for (let i = 0; i < monthlyHistData.length; i += 120) {
        groupedMonthlyHistData.push(monthlyHistData.slice(i, i + 120));
    }

    // Prepare forecasting data by extracting only the values and ensuring they are strings
    const forecastData = forecastPeriods.map(period => String(forecastInputs[period.label] || ''));

    // Initialize empty arrays for other bases, ensuring they're strings
    const emptyAnnualForeData = Array(10).fill('');
    const emptyQuarterlyForeData = Array(40).fill('');
    const emptyMonthlyForeData = Array(120).fill('');


    // Prepare forecasting data only for the current basis, and pad other bases with empty strings
    let paddedAnnualForeData, paddedQuarterlyForeData, paddedMonthlyForeData;

    if (currentModel.basis === 'Yearly') {
        paddedAnnualForeData = [...forecastData, ...Array(10 - forecastData.length).fill('')];
        paddedQuarterlyForeData = emptyQuarterlyForeData;
        paddedMonthlyForeData = emptyMonthlyForeData;
    } else if (currentModel.basis === 'Quarterly') {
        paddedAnnualForeData = emptyAnnualForeData;
        paddedQuarterlyForeData = [...forecastData, ...Array(40 - forecastData.length).fill('')];
        paddedMonthlyForeData = emptyMonthlyForeData;
    } else if (currentModel.basis === 'Monthly') {
        paddedAnnualForeData = emptyAnnualForeData;
        paddedQuarterlyForeData = emptyQuarterlyForeData;
        paddedMonthlyForeData = [...forecastData, ...Array(120 - forecastData.length).fill('')];
    }

    // Group forecasting data into sub-arrays (if needed) and then wrap in an array
    const groupedAnnualForeData = [paddedAnnualForeData];
    const groupedQuarterlyForeData = [paddedQuarterlyForeData];
    const groupedMonthlyForeData = [paddedMonthlyForeData];

    // Prepare the row data to be sent to the backend
    const rowData = {
        rowNames: [rowName],
        rowVariables: isHistorical ? [selectedCategoryValue] : ['None'],
        categories: isHistorical ? [selectedCategory] : ['None'],
        groupedOver: isHistorical ? selectedVariable : 'None',
        dateColumn: isHistorical ? selectedDateColumn : 'None',
        sourceFile: isHistorical
            ? [
                [
                    selectedSpreadsheet,
                    spreadsheets.find((s) => s.id === selectedSpreadsheet).suborganisation,
                    spreadsheets.find((s) => s.id === selectedSpreadsheet).newName,
                ],
            ]
            : ['None'],
        drivers: [selectedDriver],
        supportingAssumptionModelIds: [""],
        annualHistRows: groupedAnnualHistData,
        quarterlyHistRows: groupedQuarterlyHistData,
        monthlyHistRows: groupedMonthlyHistData,
        annualForeRows: groupedAnnualForeData,
        quarterlyForeRows: groupedQuarterlyForeData,
        monthlyForeRows: groupedMonthlyForeData,
    };

    console.log(rowData)

    try {
        const response = await api.put(
            `/models/${currentModel.id}/add-row`,
            rowData
        );
        if (response.status === 200) {
            console.log('Row data successfully imported');
            const updatedModel = response.data.model; // Assuming the updated model is returned in the response
            onRowAdded(updatedModel);
        } else {
            console.error('Unexpected response:', response.data);
        }
    } catch (error) {
        console.error('Error importing row data:', error);
    }
};


const calculateSumForPeriod = (startDate, endDate, processedData, categoryValue = selectedCategoryValue) => {
  if (!processedData || !selectedCategory || !categoryValue || !selectedVariable || !selectedDateColumn) {
    console.log('Missing required data or selections');
    return 0;
  }

  const categoryIndex = processedData.filteredHeaders.indexOf(selectedCategory);
  const variableIndex = processedData.filteredHeaders.indexOf(selectedVariable);
  const dateIndex = processedData.filteredHeaders.indexOf(selectedDateColumn);

  // Calculate the sum over the period
  const sum = processedData.processedRows.reduce((total, row) => {
    const rowDate = parseExcelDate(row[dateIndex]); // Parse date from the row using the correct index
    const categoryMatch = row[categoryIndex] === categoryValue; // Match the category value
    const isInPeriod = rowDate.isValid() && rowDate.isBetween(startDate, endDate, undefined, '[]');

    if (categoryMatch && isInPeriod) {
      const value = parseFloat(row[variableIndex] || 0); // Use the variable index to get the value
      return total + value;
    }

    return total;
  }, 0);

  return sum;
};

  const calculatePercentIncrease = (currentValue, previousValue) => {
    if (previousValue === 0 || currentValue === '') {
      return '';
    }
    return ((currentValue - previousValue) / previousValue) * 100;
  };

  const handleForecastInputChange = (period, value, periods) => {
    // Ensure historical amounts are loaded
    if (Object.keys(historicalAmounts).length === 0) {
      console.log("Historical amounts are not loaded yet. Skipping forecast calculation.");
      return;
    }
  
    const parsedValue = parseFloat(value) || ''; // Preserve the input value as it is typed
    console.log(`Entered value for ${period}: ${parsedValue}`);
  
    setForecastInputs((prevState) => {
      const updatedInputs = {
        ...prevState,
        [period]: parsedValue, // Update the specific period input
      };
  
      // Recalculate forecast amounts after setting the new inputs
      recalculateForecastAmounts(updatedInputs, periods);
  
      return updatedInputs;
    });
  };  
  
  const recalculateForecastAmounts = (inputs, periods) => {
    const updatedForecastAmounts = {};
    let previousAmount = 0;
  
    // Start with the last historical period's amount
    const lastHistPeriod = periods[currentModel.histPeriods - 1]?.label;
    previousAmount = historicalAmounts[lastHistPeriod] || 0;
    console.log(`Starting forecast calculation with last historical amount (${lastHistPeriod}): ${previousAmount}`);
  
    // Loop through all forecast periods and calculate the amounts
    for (let i = currentModel.histPeriods; i < periods.length; i++) {
      const currentPeriodLabel = periods[i].label;
      const percentageIncrease = inputs[currentPeriodLabel] || 0; // Get the input for the current period
  
      // Calculate the forecast amount based on the previous amount and the percentage increase
      const newAmount = previousAmount + (previousAmount * percentageIncrease) / 100;
      updatedForecastAmounts[currentPeriodLabel] = newAmount.toFixed(2);
  
      // Set the current period's amount as the previous amount for the next iteration
      previousAmount = newAmount;
    }
  
    setForecastAmounts(updatedForecastAmounts);
    console.log('Updated forecast amounts:', updatedForecastAmounts);
  };   

  const handleCategoryValueChange = (newCategoryValue) => {
    setSelectedCategoryValue(newCategoryValue); // Update the selected category value
  
    // Trigger recalculation of historical amounts immediately after the new category value is selected
    if (processedData && currentModel.histPeriods) {
      const periods = calculatePeriods(
        currentModel.basis,
        currentModel.currentDate,
        currentModel.histPeriods,
        0, // Only historical periods
        currentModel.yearEnd
      );
  
      const tempHistoricalAmounts = {};
      periods.forEach((period) => {
        const sum = calculateSumForPeriod(period.startDate, period.endDate, processedData, newCategoryValue); 
        tempHistoricalAmounts[period.label] = sum;
      });
  
      console.log("Updating historical amounts based on new category value:", newCategoryValue, tempHistoricalAmounts);
      setHistoricalAmounts(tempHistoricalAmounts); // Immediately set the new historical amounts
    }
  };
  

  const generateTableHeaders = () => {
    const periods = calculatePeriods(
      currentModel.basis,
      currentModel.currentDate,
      currentModel.histPeriods,
      currentModel.forePeriods,
      currentModel.yearEnd
    );

    return ['Row name', 'Driver', ...periods.map(period => period.label)];
  };

  useEffect(() => {
    if (processedData && currentModel.histPeriods && selectedCategoryValue) {
      const periods = calculatePeriods(
        currentModel.basis,
        currentModel.currentDate,
        currentModel.histPeriods,
        0, // Only historical periods
        currentModel.yearEnd
      );
  
      const tempHistoricalAmounts = {};
      periods.forEach((period) => {
        const sum = calculateSumForPeriod(period.startDate, period.endDate, processedData);  // Use processedData in sum calculation
        tempHistoricalAmounts[period.label] = sum;
      });
  
      console.log("Setting historical amounts for selected category:", selectedCategoryValue, tempHistoricalAmounts);
      setHistoricalAmounts(tempHistoricalAmounts); // Set the historical amounts
    }
  }, [processedData, currentModel.histPeriods, selectedCategoryValue]); // Run when category value or data changes
    
  
  const generateTableRows = (periods) => {
    const percentRow = []; // Row for the percentage inputs
    const amountRow = [];  // Row for the calculated forecast amounts
    let previousValue = 0;
  
    periods.forEach((period, i) => {
      const { label } = period;
  
      // For historical data
      if (isHistorical && i < currentModel.histPeriods) {
        const sum = historicalAmounts[label] || 0;
        amountRow.push(<td key={`amount-${i}`}>{sum}</td>);
        const percentIncrease = calculatePercentIncrease(sum, previousValue);
        percentRow.push(
          <td key={`percent-${i}`}>{percentIncrease !== '' ? `${percentIncrease.toFixed(2)}%` : ''}</td>
        );
        previousValue = sum;
      } 
      // For forecast periods
      else if (i >= currentModel.histPeriods) {
        if (selectedDriver === 'Percent') {
          // Input row for percentage changes
          percentRow.push(
            <td key={`percent-${i}`}>
              <input
                type="text"
                value={forecastInputs[label] || ''}
                onChange={(e) => handleForecastInputChange(label, e.target.value, periods)} // Pass all periods
              />
              {selectedDriver === 'Percent' && '%'}
            </td>
          );
  
          // Row for forecasted amounts based on input percentages
          amountRow.push(
            <td key={`amount-${i}`}>
              {forecastAmounts[label] !== undefined && forecastAmounts[label] !== ''
                ? forecastAmounts[label]
                : ''}
            </td>
          );
        } else if (selectedDriver === 'Fixed Amount') {
          // Input row for fixed amounts
          amountRow.push(
            <td key={`amount-${i}`}>
              <input
                type="text"
                value={forecastInputs[label] || ''}
                onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
              />
            </td>
          );
        }
      }
    });
  
    // Render the table rows: one for percentage inputs and one for calculated amounts
    return (
      <>
        {selectedDriver === 'Percent' && (
          <tr>
            <td>{rowName}</td>
            <td>Percent</td>
            {percentRow}
          </tr>
        )}
        <tr>
          <td>{selectedDriver === 'Fixed Amount' ? rowName : ''}</td>
          <td>Amount</td>
          {amountRow}
        </tr>
      </>
    );
  };
  

  useEffect(() => {
    const periods = calculatePeriods(
      currentModel.basis,
      currentModel.currentDate,
      currentModel.histPeriods,
      currentModel.forePeriods,
      currentModel.yearEnd
    );
  
    // Update table rows whenever the category value changes or historical amounts are recalculated
    setTableRows(generateTableRows(periods));
  }, [
    selectedCategoryValue,  // Trigger when the selected category value changes
    forecastInputs,         // Trigger when forecast inputs change
    forecastAmounts,        // Trigger when forecast amounts change
    historicalAmounts       // Trigger when historical amounts change
  ]);
  
  
  return (
    <div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isHistorical}
            onChange={e => setIsHistorical(e.target.checked)}
          />
          Row is based on historical data
        </label>
      </div>

      <div>
        <label>Row Name:</label>
        <input
          type="text"
          value={rowName}
          onChange={e => setRowName(e.target.value)}
        />
      </div>

      {isHistorical && (
        <>
          <div>
            <label>Select Driver:</label>
            <select
              value={selectedDriver}
              onChange={e => setSelectedDriver(e.target.value)}
            >
              <option value="Percent">Percent increase</option>
              <option value="Fixed Amount">Fixed Amount</option>
            </select>
          </div>

          <SpreadsheetSelector
            spreadsheets={spreadsheets}
            selectedSpreadsheet={selectedSpreadsheet}
            setSelectedSpreadsheet={setSelectedSpreadsheet}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedVariable={selectedVariable}
            setSelectedVariable={setSelectedVariable}
            selectedDateColumn={selectedDateColumn}
            setSelectedDateColumn={setSelectedDateColumn}
            categoryValues={categoryValues}
            setCategoryValues={setCategoryValues}
            selectedCategoryValue={selectedCategoryValue}
            setSelectedCategoryValue={setSelectedCategoryValue}
            handleCategoryValueChange={handleCategoryValueChange}
            fetchSpreadsheetData={loadSpreadsheetData} // Pass down loadSpreadsheetData
            processedData={processedData} // Pass down the processed data
            currentOrgId={currentModel.organisationId}
          />
        </>
      )}

      {!isHistorical && (
        <div>
          <label>Driver:</label>
          <input type="text" value="Fixed Amount" disabled />
        </div>
      )}

      <div className="scrollable-table-container">
        <table className="data-table">
          <thead>
            <tr>
              {generateTableHeaders().map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>

      <button onClick={handleImportRowData}>Import Row Data</button>
    </div>
  );
};

export default AddRowForm;

