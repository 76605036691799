import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth, AuthProvider } from './Components/authcontext';
import './App.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';

import ScrollToTop from './Components/scrolltop';
import Navigation from './Components/nav';

import Homepage from './Components/homepage';
import DataInputPage from './Components/datainput';
import ColumnSelectionPage from './Components/columnselectionpage';
import DataViewPage from './Components/dataview';
import ColumnMappingPage from './Components/columnmapping';
import RowMappingPage from './Components/rowmapping';
import CustomColumnsPage from './Components/customcolumnpage';
import DataAnalysisPage from './Components/dataanalysis';

import ForecastingSidebar from './Components/Forecasting/forecastingsidebar';
import ForecastingPage from './Components/Forecasting/forecasting';
import SupportingPage from './Components/Forecasting/supporting';
import ForecastingLandingPage from './Components/Forecasting/forelandingpage';

import DataSidebar from './Components/datasidebar'; // Import the DataSidebar

import LoginPage from './Components/Login/login';
import Registration from './Components/Login/registration';
import PasswordReset from './Components/Login/passwordreset';
import VerifyEmail from './Components/Login/verifyemail';
import RequestReset from './Components/Login/requestreset';

function App() {
  const { setCurrentModel, models } = useAuth();

  const Layout = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn, isLoading } = useAuth();

    // Public paths where no sidebar is needed
    const publicPaths = ['/', '/register', '/verifyemail', '/login', '/requestreset', '/passwordreset'];
    
    // Paths that should show the Forecasting sidebar
    const forecastingPaths = ['/forecasting', '/forecastinglanding', '/forecasting/:modelId/pl', '/forecasting/:modelId/bs', '/forecasting/:modelId/cf'];
    
    // Paths that should show the Data sidebar
    const dataPaths = ['/datainput', '/dataview', '/columnselection', '/columnmapping', '/rowmapping', '/customcolumns'];

    useEffect(() => {
      if (isLoading) {
        return;
      }

      if (!isLoggedIn && !publicPaths.includes(location.pathname)) {
        navigate('/');
      } else if (isLoggedIn && publicPaths.includes(location.pathname)) {
        navigate('/home', { replace: true });
      }
    }, [isLoggedIn, isLoading, location.pathname, navigate]);

    // Check if the current path matches the forecasting or data paths
    const isForecastingRoute = forecastingPaths.some(path => location.pathname.startsWith(path));
    const isDataRoute = dataPaths.some(path => location.pathname.startsWith(path));

    return (
      <>
        {/* Navigation is displayed on all non-public pages */}
        {!publicPaths.includes(location.pathname) && <Navigation />}
        
        {/* Conditionally render the appropriate sidebar */}
        {isForecastingRoute && <ForecastingSidebar />}
        {isDataRoute && <DataSidebar />}

        {/* Main content area */}
        <div className={!publicPaths.includes(location.pathname) ? `content ${isForecastingRoute || isDataRoute ? 'shifted' : ''}` : 'content login'}>
          {children}
        </div>
      </>
    );
  };

  const handleModelRoute = (modelId) => {
    const model = models.find(m => m.id === parseInt(modelId));
    if (model) {
      setCurrentModel(model);
    }
  };

  return (
    <AuthProvider>
      <div className="App">
        <Router>
          <ScrollToTop />
          <div className="homecontainer">
            <Routes>
              <Route path="/" element={<Layout><LoginPage /></Layout>} />
              <Route path="/register" element={<Layout><Registration /></Layout>} />
              <Route path="/verifyemail" element={<Layout><VerifyEmail /></Layout>} />
              <Route path="/requestreset" element={<Layout><RequestReset /></Layout>} />
              <Route path="/passwordreset" element={<Layout><PasswordReset /></Layout>} />

              <Route path="/home" element={<Layout><Homepage /></Layout>} />
              <Route path="/datainput" element={<Layout><DataInputPage /></Layout>} />
              <Route path="/columnselection/:spreadsheetId" element={<Layout><ColumnSelectionPage /></Layout>} />
              <Route path="/dataview/:spreadsheetId" element={<Layout><DataViewPage /></Layout>} />
              <Route path="/columnmapping" element={<Layout><ColumnMappingPage /></Layout>} />
              <Route path="/rowmapping" element={<Layout><RowMappingPage /></Layout>} />
              <Route path="/customcolumns" element={<Layout><CustomColumnsPage /></Layout>} />
              <Route path="/dataanalysis" element={<Layout><DataAnalysisPage /></Layout>} />
              <Route path="/forecasting" element={<Layout><ForecastingPage /></Layout>} />
              <Route path="/forecastinglanding" element={<Layout><ForecastingLandingPage /></Layout>} />

              {/* Routes for specific models */}
              <Route path="/forecasting/:modelId/pl" element={<Layout><ForecastingPage /></Layout>} onEnter={(props) => handleModelRoute(props.match.params.modelId)} />
              <Route path="/forecasting/:modelId/bs" element={<Layout><ForecastingPage /></Layout>} onEnter={(props) => handleModelRoute(props.match.params.modelId)} />
              <Route path="/forecasting/:modelId/cf" element={<Layout><ForecastingPage /></Layout>} onEnter={(props) => handleModelRoute(props.match.params.modelId)} />
              <Route path="/forecasting/:modelId/sa" element={<Layout><SupportingPage /></Layout>} onEnter={(props) => handleModelRoute(props.match.params.modelId)} />
            </Routes>
          </div>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
