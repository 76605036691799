import React, { useState, useEffect } from 'react';
import { useAuth } from '../authcontext';
import AddRowForm from './addrow';
import Popup from './popup';
import api from '../api'; // Assuming you have an API utility for making requests
import { useParams, useNavigate } from 'react-router-dom'; 

const ForecastingPage = () => {
    const { modelId } = useParams(); // Get the modelId from the URL
    const { currentModel, setCurrentModel, spreadsheets, models, isLoading: isModelsLoading } = useAuth();
    const [tableHeaders, setTableHeaders] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [showRowForm, setShowRowForm] = useState(false); // Controls simplified row form
    const [showDetailedRowForm, setShowDetailedRowForm] = useState(false); // Controls detailed row form
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null); // State to hold the clicked row data
    const [detailedRowName, setDetailedRowName] = useState(''); // State for the custom row name
    const navigate = useNavigate();

    // Fetch the model whenever the modelId changes and models are loaded
    useEffect(() => {
        // Fetch the model only if modelId is present and models have been loaded
        if (modelId && !isModelsLoading && models.length > 0) {
            fetchModel(modelId);
        } else if (!isModelsLoading && models.length === 0) {
            // Optionally fetch the models again if not loaded (depending on context)
            console.warn('No models available.');
        }
    }, [modelId, isModelsLoading, models]);
       

    const fetchModel = async (modelId) => {
        try {
            setIsLoading(true);
            const model = models.find((m) => m.id === parseInt(modelId, 10));

            if (model) {
                setCurrentModel(model); // Set the new model in context
                const histHeaders = getHeadersByBasis(model, 'Hist');
                const foreHeaders = getHeadersByBasis(model, 'Fore');

                const headers = ['Row name', 'Driver', ...histHeaders.slice(-model.histPeriods), ...foreHeaders.slice(0, model.forePeriods)];
                setTableHeaders(headers);

                processRowsData(model, model.histPeriods, model.forePeriods);
            } else {
                console.error('Model not found for modelId:', modelId);
            }
        } catch (error) {
            console.error('Error fetching model:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getHeadersByBasis = (model, type) => {
        let headers = [];
        if (!model) {
            console.error('Model is undefined');
            return headers;
        }
    
        try {
            let rawHeaders;
            switch (model.basis) {
                case 'Yearly':
                    rawHeaders = type === 'Hist' ? model.annualHistHeaders : model.annualForeHeaders;
                    break;
                case 'Quarterly':
                    rawHeaders = type === 'Hist' ? model.quarterlyHistHeaders : model.quarterlyForeHeaders;
                    break;
                case 'Monthly':
                    rawHeaders = type === 'Hist' ? model.monthlyHistHeaders : model.monthlyForeHeaders;
                    break;
                default:
                    console.error('Invalid basis specified in the model');
                    return [];
            }
    
            headers = JSON.parse(rawHeaders);
    
            if (!Array.isArray(headers)) {
                console.error('Headers are not an array:', headers);
                return [];
            }
    
            return headers.map(header => Array.isArray(header) ? header[0] : header.label || '');
        } catch (error) {
            console.error('Error parsing headers:', error);
            return [];
        }
    };

    const processRowsData = async (model, histPeriodsCount, forePeriodsCount) => {
        if (!model) {
            console.error('Model is undefined');
            setRowsData([]);
            return;
        }
    
        setIsLoading(true); 
        const rows = [];
    
        const {
            rowNames = [],
            drivers = [],
            annualHistRows = [],
            quarterlyHistRows = [],
            monthlyHistRows = [],
            annualForeRows = [],
            quarterlyForeRows = [],
            monthlyForeRows = [],
        } = model;
    
        if (!Array.isArray(rowNames) || rowNames.length === 0) {
            console.warn('No rows found in the model');
            setRowsData([]); 
            setIsLoading(false); 
            return;
        }
    
        let histRowsData, foreRowsData;
        switch (model.basis) {
            case 'Yearly':
                histRowsData = JSON.parse(annualHistRows);
                foreRowsData = JSON.parse(annualForeRows);
                break;
            case 'Quarterly':
                histRowsData = JSON.parse(quarterlyHistRows);
                foreRowsData = JSON.parse(quarterlyForeRows);
                break;
            case 'Monthly':
                histRowsData = JSON.parse(monthlyHistRows);
                foreRowsData = JSON.parse(monthlyForeRows);
                break;
            default:
                console.error('Invalid basis specified in the model');
                setRowsData([]);
                setIsLoading(false);
                return;
        }
    
        for (let i = 0; i < rowNames.length; i++) {
            const rowData = {
                name: rowNames[i] || '',
                driver: drivers[i] || '',
                values: [],
            };
    
            if (histRowsData && Array.isArray(histRowsData[i])) {
                const histValues = histRowsData[i].slice(-histPeriodsCount);
                rowData.values.push(...histValues);
            } else {
                console.warn(`No historical data for row ${i + 1}`);
            }
    
            if (foreRowsData && Array.isArray(foreRowsData[i])) {
                if (drivers[i] === 'Percent') {
                    let lastHistoricalValue = parseFloat(rowData.values[rowData.values.length - 1]) || 0;
                    for (let j = 0; j < forePeriodsCount; j++) {
                        const percentIncrease = parseFloat(foreRowsData[i][j]) || 0;
                        if (isNaN(lastHistoricalValue) || isNaN(percentIncrease)) {
                            console.warn(`Invalid values for row ${i + 1}: lastHistoricalValue=${lastHistoricalValue}, percentIncrease=${percentIncrease}`);
                            rowData.values.push('N/A');
                        } else {
                            const newForecastValue = lastHistoricalValue + (lastHistoricalValue * percentIncrease / 100);
                            rowData.values.push(newForecastValue.toFixed(2));
                            lastHistoricalValue = newForecastValue;
                        }
                    }
                } else {
                    const foreValues = foreRowsData[i].slice(0, forePeriodsCount);
                    rowData.values.push(...foreValues);
                }
            } else {
                console.warn(`No forecasting data for row ${i + 1}`);
            }
    
            rows.push(rowData);
        }
    
        setRowsData(rows);
        setIsLoading(false);
    };      

    const handleRowClick = (row, rowIndex) => {
        // Ensure the supportingAssumptionModelIds is parsed correctly as JSON
        let supportingAssumptionModelIds = [];
    
        try {
            supportingAssumptionModelIds = JSON.parse(currentModel.supportingAssumptionModelIds);
        } catch (error) {
            console.error('Error parsing supportingAssumptionModelIds:', error);
        }
    
        // Check if the driver is "Supporting Assumptions"
        if (row.driver === 'Supporting Assumptions') {
            const supportingAssumptionId = supportingAssumptionModelIds?.[rowIndex]; // Safely access the ID using rowIndex
            if (supportingAssumptionId) {
                // Correctly navigate to the supporting assumption model page with the ID
                navigate(`/forecasting/${supportingAssumptionId}/sa`);
            } else {
                console.warn('No supporting assumption ID found for this row');
            }
        } else {
            // Proceed with showing the popup for other drivers
            setSelectedRowData(row);
        }
    };
    
    const handleValueChange = (index, newValue) => {
        const updatedRowData = { ...selectedRowData };
        updatedRowData.values[index] = newValue;
        setSelectedRowData(updatedRowData); // Update the selected row data
    };

    const closePopup = () => {
        setSelectedRowData(null); // Close the popup
    };

    const handleRowAdded = () => {
        setShowRowForm(false);
        setShowDetailedRowForm(false);
        window.location.reload();  // Force page reload to fetch new data
    };

    // API Call to create a new detailed model
    const createDetailedModel = async () => {
        try {
            const response = await api.post('/models', {
                organisationId: currentModel.organisationId,
                superModelId: currentModel.superModelId, // This links the new model to the current model
                modelName: detailedRowName,
                modelType: 'Supporting Assumptions',
                basis: currentModel.basis,
                histPeriods: currentModel.histPeriods,
                forePeriods: currentModel.forePeriods,
                yearEnd: currentModel.yearEnd,
                templateUrl: currentModel.templateUrl,
                annualHistHeaders: currentModel.annualHistHeaders,
                quarterlyHistHeaders: currentModel.quarterlyHistHeaders,
                monthlyHistHeaders: currentModel.monthlyHistHeaders,
                annualForeHeaders: currentModel.annualForeHeaders,
                quarterlyForeHeaders: currentModel.quarterlyForeHeaders,
                monthlyForeHeaders: currentModel.monthlyForeHeaders,
            });
            if (response.status === 201) {
                console.log('Detailed model created successfully');

                const newSupportingAssumptionId = response.data.model.id;

                // Add a new row to the current model with padding and the supporting assumption ID
                await addDetailedRowToCurrentModel(newSupportingAssumptionId);
                handleRowAdded(); // Optionally reload the rows after creation
                navigate(`/forecasting/${newSupportingAssumptionId}/sa`);
                window.location.reload()
            } else {
                console.error('Failed to create detailed model');
            }
        } catch (error) {
            console.error('Error creating detailed model:', error);
        }
    };     

    const addDetailedRowToCurrentModel = async (supportingAssumptionId) => {
        try {
            // Padding for historical and forecast data (10 for annual, 40 for quarterly, 120 for monthly)
            const emptyAnnualHistData = Array(10).fill('');
            const emptyQuarterlyHistData = Array(40).fill('');
            const emptyMonthlyHistData = Array(120).fill('');
            const emptyAnnualForeData = Array(10).fill('');
            const emptyQuarterlyForeData = Array(40).fill('');
            const emptyMonthlyForeData = Array(120).fill('');
    
            // Prepare the row data with padding
            const rowData = {
                rowNames: [detailedRowName], // Use the entered detailed row name
                rowVariables: ['None'],
                categories: ['None'],
                groupedOver: 'None',
                dateColumn: 'None',
                sourceFile: ['None'],
                drivers: ['Supporting Assumptions'], // Set the driver to "Supporting Assumptions"
                supportingAssumptionModelIds: [String(supportingAssumptionId)], // Set the new supporting assumption model ID
                annualHistRows: [emptyAnnualHistData],
                quarterlyHistRows: [emptyQuarterlyHistData],
                monthlyHistRows: [emptyMonthlyHistData],
                annualForeRows: [emptyAnnualForeData],
                quarterlyForeRows: [emptyQuarterlyForeData],
                monthlyForeRows: [emptyMonthlyForeData],
            };
    
            // Send the new row to the backend to add it to the current model
            const response = await api.put(`/models/${currentModel.id}/add-row`, rowData);
    
            if (response.status === 200) {
                console.log('Detailed row added successfully');
            } else {
                console.error('Failed to add detailed row to model:', response.data);
            }
        } catch (error) {
            console.error('Error adding detailed row:', error);
        }
    };

    const handleSave = async (updatedData) => {
        try {
            const { rowIndex, updatedValues, basis } = updatedData;
    
            console.log("Sending updatedValues to backend: ", updatedValues);
    
            const response = await api.put(`/model/update-forecasting-row/${currentModel.id}`, {
                rowIndex: rowIndex,
                updatedValues: updatedValues,
                basis: basis,
            });
    
            if (response.status === 200) {
                closePopup();
                window.location.reload()
            } else {
                console.error('Failed to save changes.');
            }
        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };   

    const toggleShowRowForm = () => {
        setShowRowForm(!showRowForm);
        setShowDetailedRowForm(false); // Hide detailed row form when showing simplified form
    };

    const toggleShowDetailedRowForm = () => {
        setShowDetailedRowForm(!showDetailedRowForm);
        setShowRowForm(false); // Hide simplified row form when showing detailed form
    };     

    return (
        <div>
            {currentModel && (
                <>
                    <h2>{`${currentModel.modelName} - ${currentModel.modelType}`}</h2>
                    <div className="button-container">
                    <button onClick={toggleShowRowForm}>Add Simplified Row</button>
                    <button onClick={toggleShowDetailedRowForm}>Add Detailed Row</button>
                    </div>

                    {showRowForm && (
                        <AddRowForm 
                            currentModel={currentModel}
                            spreadsheets={spreadsheets}
                            onRowAdded={handleRowAdded}
                        />
                    )}

                    {showDetailedRowForm && (
                        <div>
                            <input 
                                type="text" 
                                placeholder="Enter detailed row name" 
                                value={detailedRowName}
                                onChange={(e) => setDetailedRowName(e.target.value)}
                            />
                            <button onClick={createDetailedModel}>
                                Confirm
                            </button>
                        </div>
                    )}

                    {isLoading ? (
                        <p>Loading data...</p>
                    ) : (
                        <div className='scrollable-table-container'>
                            <table className='data-table data-table--model'>
                                <thead>
                                    <tr>
                                        {tableHeaders.map((header, index) => {
                                            const isForecastingColumn = index >= tableHeaders.length - currentModel.forePeriods;
                                            return (
                                                <th 
                                                    key={index} 
                                                    className={isForecastingColumn ? 'forecasting-column' : ''}
                                                >
                                                    {header}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(rowsData) && rowsData.length > 0 ? (
                                        rowsData.map((row, rowIndex) => (
                                            <tr 
                                                key={rowIndex} 
                                                onClick={() => handleRowClick(row, rowIndex)} // Pass row and rowIndex
                                            >
                                                <td>{row.name}</td>
                                                <td>{row.driver}</td>
                                                {row.values.map((value, valueIndex) => {
                                                    const isForecastingColumn = valueIndex >= row.values.length - currentModel.forePeriods;
                                                    return (
                                                        <td 
                                                            key={valueIndex} 
                                                            className={isForecastingColumn ? 'forecasting-column' : ''}
                                                        >
                                                            {value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={tableHeaders.length}>No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}

            {/* Popup for editing row */}
            {selectedRowData && (
                <Popup 
                headers={tableHeaders} 
                rowData={selectedRowData} 
                forePeriods={currentModel.forePeriods}
                currentModel={currentModel}
                rowIndex={rowsData.indexOf(selectedRowData)}
                onClose={closePopup} 
                onValueChange={handleValueChange} 
                onSave={handleSave}
              />              
            )}
        </div>
    );
};

export default ForecastingPage;
