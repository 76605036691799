import React, { useEffect } from 'react';

const SpreadsheetSelector = ({
  spreadsheets,
  selectedSpreadsheet,
  setSelectedSpreadsheet,
  selectedCategory,
  setSelectedCategory,
  selectedVariable,
  setSelectedVariable,
  selectedDateColumn,
  setSelectedDateColumn,
  categoryValues,
  setCategoryValues,
  selectedCategoryValue,
  setSelectedCategoryValue,
  handleCategoryValueChange,
  isHistorical,
  fetchSpreadsheetData, // Expecting fetchSpreadsheetData as prop for fetching and processing
  processedData, // Processed spreadsheet data after calling fetchSpreadsheetData
  currentOrgId, // Receive currentOrgId as a prop
}) => {

  useEffect(() => {
    if (selectedSpreadsheet) {
      fetchSpreadsheetData(selectedSpreadsheet, spreadsheets.find(s => s.id === selectedSpreadsheet));
    }
  }, [selectedSpreadsheet]);

  const handleSpreadsheetChange = async (e) => {
    const selectedSpreadsheetId = parseInt(e.target.value, 10);
    setSelectedSpreadsheet(selectedSpreadsheetId);
    setSelectedCategory('');
    setSelectedVariable('');
    setSelectedDateColumn('');
    setCategoryValues([]);
    setSelectedCategoryValue('');
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);
    setSelectedCategoryValue(''); // Reset category value when category changes
  
    // Find the index of the selected category in filteredHeaders
    const categoryIndex = processedData.filteredHeaders.indexOf(selectedCategory);
  
    // Map the rows to unique values based on the found index in filteredHeaders
    const uniqueValues = [
      ...new Set(processedData.processedRows.map(row => row[categoryIndex])),
    ];
  
    // Set the unique values for the selected category
    setCategoryValues(uniqueValues);
  };
  

  const handleCategoryValueSelect = (e) => {
    handleCategoryValueChange(e.target.value); // Call the handler for recalculation when a new category value is selected
  };

  // Filter spreadsheets based on the current organization
  const filteredSpreadsheets = spreadsheets.filter(
    (spreadsheet) => spreadsheet.organisationId === currentOrgId
  );

  return (
    <>
      <div>
        <label>Select Spreadsheet:</label>
        <select value={selectedSpreadsheet} onChange={handleSpreadsheetChange}>
          <option value="" disabled>
            Select spreadsheet
          </option>
          {filteredSpreadsheets.map((spreadsheet) => (
            <option key={spreadsheet.id} value={spreadsheet.id}>
              {spreadsheet.suborganisation} - {spreadsheet.newName}
            </option>
          ))}
        </select>
      </div>

      {processedData && (
        <>
      <div>
        <label>Select Category:</label>
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="" disabled>
            Select category
          </option>
          {processedData.filteredHeaders
            .filter(header => processedData.catCols.includes(header)) 
            .map((header, index) => (
            <option key={index} value={header}>
              {header}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Select Variable to Sum Over:</label>
        <select
          value={selectedVariable}
          onChange={(e) => setSelectedVariable(e.target.value)}
        >
          <option value="" disabled>Select variable</option>
          {processedData.filteredHeaders
            .filter(header => processedData.numCols.includes(header)) 
            .map((header, index) => (
              <option key={index} value={header}>
                {header}
              </option>
            ))}
        </select>
      </div>

      <div>
        <label>Select Date Column:</label>
        <select
          value={selectedDateColumn}
          onChange={(e) => setSelectedDateColumn(e.target.value)}
        >
          <option value="" disabled>Select date column</option>
          {processedData.filteredHeaders
            .filter(header => processedData.dateCols.includes(header)) 
            .map((header, index) => (
              <option key={index} value={header}>
                {header}
              </option>
            ))}
        </select>
      </div>

      {selectedCategory && (
        <div>
          <label>Select Category Value:</label>
          <select
            value={selectedCategoryValue}
            onChange={handleCategoryValueSelect}
          >
            <option value="" disabled>
              Select category value
            </option>
            {categoryValues.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      )}
      </>
      )}
    </>
  );
};

export default SpreadsheetSelector;
