import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './authcontext';
import api from './api';
import AutosuggestDropdown from './autosuggestdropdown';

function ColumnMappingPage() {
  const { spreadsheets, currentOrg } = useAuth();
  const [localHeaders, setLocalHeaders] = useState({});
  const [originalHeaders, setOriginalHeaders] = useState({});
  const [columnStatuses, setColumnStatuses] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [invalidHeaders, setInvalidHeaders] = useState({});
  const [showSuborgFilter, setShowSuborgFilter] = useState(false); // For toggling suborg filter
  const [selectedSuborgs, setSelectedSuborgs] = useState([]); // For filtering spreadsheets by suborg
  const [expandedSuborgs, setExpandedSuborgs] = useState({}); // Track expanded/collapsed state for each suborg
  const [editableColumnNames, setEditableColumnNames] = useState([]); // New state to hold all unique editable column names
  const activeHeaderRef = useRef(null);

  useEffect(() => {
    if (currentOrg && spreadsheets.length > 0) {
      const orgSpreadsheets = spreadsheets.filter(
        (spreadsheet) => spreadsheet.organisationId === currentOrg.id
      );
    
      const headers = {};
      const originals = {};
      const statuses = {};
      const allEditableColumnNames = new Set();  // Store unique column names with valid statuses (Date, Categorical, Numerical)
  
      orgSpreadsheets.forEach((spreadsheet) => {
        try {
          const parsedHeaders = spreadsheet.editedHeaders || [];
          const parsedOriginals = spreadsheet.originalHeaders || [];
          const parsedStatuses = spreadsheet.columnStatus || [];
  
          // Store the full arrays without filtering or sorting
          headers[spreadsheet.id] = parsedHeaders;
          originals[spreadsheet.id] = parsedOriginals;
          statuses[spreadsheet.id] = parsedStatuses;
  
          // Collect only headers with a valid status (Date, Categorical, Numerical)
          parsedHeaders.forEach((header, index) => {
            if (['Date', 'Categorical', 'NumericalEditable', 'CategoricalEditable'].includes(parsedStatuses[index])) {
              allEditableColumnNames.add(header);
            }
          });
          parsedOriginals.forEach((header, index) => {
            if (['Date', 'Categorical', 'NumericalEditable', 'CategoricalEditable'].includes(parsedStatuses[index])) {
              allEditableColumnNames.add(header);
            }
          });
        } catch (error) {
          console.error(`Failed to parse headers for spreadsheet ${spreadsheet.id}`, error);
          headers[spreadsheet.id] = [];
          originals[spreadsheet.id] = [];
          statuses[spreadsheet.id] = [];
        }
      });
  
      setLocalHeaders(headers);
      setOriginalHeaders(originals);
      setColumnStatuses(statuses);
  
      // Update the state with all valid unique column names across all selected spreadsheets
      setEditableColumnNames([...allEditableColumnNames]);
  
      // Set default selected suborgs (all suborgs)
      const allSuborgs = [...new Set(spreadsheets.map((sp) => sp.suborganisation || 'No Suborganisation'))];
      setSelectedSuborgs(allSuborgs);
  
      // Set default expanded state (all expanded)
      const initialExpandedState = {};
      allSuborgs.forEach((suborg) => {
        initialExpandedState[suborg] = true;  // Set to true to expand all by default
      });
      setExpandedSuborgs(initialExpandedState);  // Set expanded state
    }
  }, [currentOrg, spreadsheets]);  
   

  const toggleSuborgFilter = () => {
    setShowSuborgFilter(!showSuborgFilter);
  };

  const handleSuborgCheckboxChange = (suborg) => {
    setSelectedSuborgs((prevSelected) =>
      prevSelected.includes(suborg)
        ? prevSelected.filter((s) => s !== suborg) // Remove if already selected
        : [...prevSelected, suborg] // Add if not selected
    );
  };

  const untickAllSuborgs = () => {
    setSelectedSuborgs([]);
  };

  const toggleSuborgExpand = (suborg) => {
    setExpandedSuborgs((prevExpanded) => ({
      ...prevExpanded,
      [suborg]: !prevExpanded[suborg], // Toggle the expanded state
    }));
  };

  const handleHeaderChange = (spreadsheetId, index, value) => {
    const newHeaders = [...localHeaders[spreadsheetId]];
    newHeaders[index] = value;
    setLocalHeaders({
      ...localHeaders,
      [spreadsheetId]: newHeaders,
    });
  
    setDropdownVisible({
      ...dropdownVisible,
      [spreadsheetId]: index,
    });
  
    if (value.trim() === '') {
      setInvalidHeaders({
        ...invalidHeaders,
        [`${spreadsheetId}-${index}`]: true,
      });
    } else {
      const updatedInvalidHeaders = { ...invalidHeaders };
      delete updatedInvalidHeaders[`${spreadsheetId}-${index}`];
      setInvalidHeaders(updatedInvalidHeaders);
    }
  };  

  const handleStatusChange = (spreadsheetId, index, value) => {
    const updatedStatuses = [...columnStatuses[spreadsheetId]];
    updatedStatuses[index] = value;
    setColumnStatuses({
      ...columnStatuses,
      [spreadsheetId]: updatedStatuses
    });
  };

  const handleRemove = async (spreadsheetId, index) => {
    const updatedStatuses = [...columnStatuses[spreadsheetId]];
    if (updatedStatuses[index] === 'Date') {
      updatedStatuses[index] = 'DateUnselected';
    } else if (updatedStatuses[index] === 'Categorical') {
      updatedStatuses[index] = 'CategoricalUnselected';
    } else if (updatedStatuses[index] === 'CategoricalEditable' || updatedStatuses[index] === 'NumericalEditable') {
      updatedStatuses[index] = 'NumericalUnselected';
    } 

    setColumnStatuses({
      ...columnStatuses,
      [spreadsheetId]: updatedStatuses
    });

    try {
      const token = localStorage.getItem('token');
      await api.put(`/spreadsheets/${spreadsheetId}/headers`, {
        editedHeaders: JSON.stringify(localHeaders[spreadsheetId]),
        columnStatuses: JSON.stringify(updatedStatuses)
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      window.location.reload(); // Refresh the page after removing a field

    } catch (error) {
      console.error('Error removing field:', error);
    }
  };

  const handleSave = async (spreadsheetId) => {
    if (Object.keys(invalidHeaders).length > 0) {
      alert('You cannot leave any empty editable headers.');
      return;
    }
  
    // Validate for duplicate headers
    if (!validateUniqueHeaders(spreadsheetId)) {
      alert('There are duplicate headers in the spreadsheet. Please ensure all headers are unique.');
      return;
    }
  
    // Validate for conflicting types
    const conflicts = validateNoConflictingTypes(spreadsheetId, localHeaders[spreadsheetId], columnStatuses[spreadsheetId]);
    if (conflicts.length > 0) {
      const conflictMessages = conflicts.map(c => 
        `Conflict: Column "${c.header}" exists as "${c.existingStatus}" in "${c.conflictingSpreadsheet}" but is being saved as "${c.newStatus}".`
      ).join('\n');
      
      // Ask the user if they want to disregard the conflicts and save anyway
      const confirmSave = window.confirm(
        `The following conflicts were found:\n\n${conflictMessages}\n\nDo you want to disregard these warnings and save anyway?`
      );
      
      if (!confirmSave) {
        return; // If the user chooses to cancel, stop the save process
      }
    }
  
    const token = localStorage.getItem('token');
    try {
      const editedHeaders = localHeaders[spreadsheetId];
      const updatedStatuses = columnStatuses[spreadsheetId];
  
      await api.put(`/spreadsheets/${spreadsheetId}/headers`, {
        editedHeaders: JSON.stringify(editedHeaders),
        columnStatuses: JSON.stringify(updatedStatuses),
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      window.location.reload(); // Refresh the page after saving
    } catch (error) {
      console.error('Error saving headers:', error);
    }
  };  

  const handleSaveAll = async () => {
    if (Object.keys(invalidHeaders).length > 0) {
      alert('You cannot leave any empty editable headers.');
      return;
    }
  
    // Validate for duplicate headers in all spreadsheets
    for (const spreadsheetId of Object.keys(localHeaders)) {
      if (!validateUniqueHeaders(spreadsheetId)) {
        alert('There are duplicate headers in one or more spreadsheets. Please ensure all headers are unique.');
        return;
      }
  
      // Validate for conflicting types across all spreadsheets
      const conflicts = validateNoConflictingTypes(spreadsheetId, localHeaders[spreadsheetId], columnStatuses[spreadsheetId]);
      if (conflicts.length > 0) {
        const conflictMessages = conflicts.map(c => 
          `Conflict: Column "${c.header}" exists as "${c.existingStatus}" in "${c.conflictingSpreadsheet}" but is being saved as "${c.newStatus}".`
        ).join('\n');
  
        // Ask the user if they want to disregard the conflicts and save anyway
        const confirmSave = window.confirm(
          `The following conflicts were found:\n\n${conflictMessages}\n\nDo you want to disregard these warnings and save anyway?`
        );
        
        if (!confirmSave) {
          return; // If the user chooses to cancel, stop the save process
        }
      }
    }
  
    setIsSaving(true);
    const token = localStorage.getItem('token');
    try {
      await Promise.all(
        Object.keys(localHeaders).map(async (spreadsheetId) => {
          const editedHeaders = localHeaders[spreadsheetId];
          const updatedStatuses = columnStatuses[spreadsheetId];
  
          await api.put(`/spreadsheets/${spreadsheetId}/headers`, {
            editedHeaders: JSON.stringify(editedHeaders),
            columnStatuses: JSON.stringify(updatedStatuses),
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        })
      );
  
      window.location.reload(); // Refresh the page after saving all headers
    } catch (error) {
      console.error('Error saving all headers:', error);
    } finally {
      setIsSaving(false);
    }
  };  

// Function to check for duplicate headers
const validateUniqueHeaders = (spreadsheetId) => {
    const headers = localHeaders[spreadsheetId];
    const headerSet = new Set();

    for (let header of headers) {
        if (headerSet.has(header)) {
            return false; // Duplicate found
        }
        headerSet.add(header);
    }
    return true; // No duplicates
};

// Function to check for conflicting column types (Numerical vs Date/Categorical, etc.)
const validateNoConflictingTypes = (spreadsheetId, headers, statuses) => {
  const conflicts = [];

  // Iterate through the current spreadsheet's headers
  headers.forEach((header, index) => {
    const currentStatus = statuses[index];

    // Ignore unselected statuses
    if (['DateUnselected', 'NumericalUnselected', 'CategoricalUnselected'].includes(currentStatus)) {
      return;
    }

    // Compare against all other spreadsheets' headers
    spreadsheets.forEach((spreadsheet) => {
      // Skip the current spreadsheet
      if (spreadsheet.id === spreadsheetId) return;

      spreadsheet.editedHeaders.forEach((existingHeader, i) => {
        const existingStatus = spreadsheet.columnStatus[i];

        // Ignore unselected statuses in other spreadsheets as well
        if (['DateUnselected', 'NumericalUnselected', 'CategoricalUnselected'].includes(existingStatus)) {
          return;
        }

        // If headers match and their statuses differ (ignoring unselected ones), flag a conflict
        if (existingHeader === header && existingStatus !== currentStatus) {
          conflicts.push({
            header,
            conflictingSpreadsheet: spreadsheet.newName,
            existingStatus: existingStatus,
            newStatus: currentStatus
          });
        }
      });
    });

    // Also check within pending save headers in other spreadsheets
    Object.keys(localHeaders).forEach((otherSpreadsheetId) => {
      if (otherSpreadsheetId === spreadsheetId) return;

      localHeaders[otherSpreadsheetId].forEach((otherHeader, i) => {
        const otherStatus = columnStatuses[otherSpreadsheetId][i];

        // Ignore unselected statuses
        if (['DateUnselected', 'NumericalUnselected', 'CategoricalUnselected'].includes(otherStatus)) {
          return;
        }

        // Check for conflicts with pending changes
        if (otherHeader === header && otherStatus !== currentStatus) {
          conflicts.push({
            header,
            conflictingSpreadsheet: spreadsheets.find(s => s.id === parseInt(otherSpreadsheetId))?.newName,
            existingStatus: otherStatus,
            newStatus: currentStatus
          });
        }
      });
    });
  });

  // Return conflicts found
  return conflicts;
};

  const handleDocumentClick = (event) => {
    if (
      activeHeaderRef.current &&
      !activeHeaderRef.current.contains(event.target)
    ) {
      const [spreadsheetId, index] = activeHeaderRef.current.dataset.key.split('-');
      handleSave(spreadsheetId);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [localHeaders]);

  const handleHeaderFocus = (spreadsheetId, index) => {
    setDropdownVisible({
      ...dropdownVisible,
      [spreadsheetId]: index
    });

    activeHeaderRef.current = document.querySelector(`[data-key="${spreadsheetId}-${index}"]`);
  };

  const handleHeaderBlur = (spreadsheetId, index) => {
    setDropdownVisible({});

    if (localHeaders[spreadsheetId][index].trim() === '') {
      setInvalidHeaders({
        ...invalidHeaders,
        [`${spreadsheetId}-${index}`]: true
      });
    } else {
      const updatedInvalidHeaders = { ...invalidHeaders };
      delete updatedInvalidHeaders[`${spreadsheetId}-${index}`];
      setInvalidHeaders(updatedInvalidHeaders);
    }
    handleSave(spreadsheetId);
  };

  // Filter the spreadsheets to only show those from the selected suborganisations
  const filteredSpreadsheets = spreadsheets.filter(
    (spreadsheet) => selectedSuborgs.includes(spreadsheet.suborganisation || 'No Suborganisation')
  );

  const uniqueSuborgs = [...new Set(spreadsheets.map((sp) => sp.suborganisation || 'No Suborganisation'))];

  return (
    <div>
      <h1>Column Mapping</h1>
      {currentOrg && (
        <div>
          <button onClick={toggleSuborgFilter}>
            {showSuborgFilter ? 'Hide Suborganisation Filter' : 'Only Show Certain Suborganisations'}
          </button>
  
          {showSuborgFilter && (
            <div>
              <button onClick={untickAllSuborgs}>Untick All</button>
              {uniqueSuborgs.map((suborg) => (
                <div key={suborg}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedSuborgs.includes(suborg)}
                      onChange={() => handleSuborgCheckboxChange(suborg)}
                    />
                    {suborg}
                  </label>
                </div>
              ))}
            </div>
          )}
  
          <p>You can edit which columns are carried forward to the analysis by clicking "Edit" on the spreadsheet in the Data Input page.</p>
          <button className='data-table-button' onClick={handleSaveAll} disabled={isSaving}>
            Save All
          </button>
  
          {uniqueSuborgs.map((suborg) => (
            <div key={suborg}>
              {/* Suborganisation section with chevron */}
              <div
                className="suborg-header"
                onClick={() => toggleSuborgExpand(suborg)}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              >
                <span style={{ marginRight: '10px' }}>
                  {expandedSuborgs[suborg] ? '▼' : '▶'}
                </span>
                <h2 style={{ margin: 0 }}>{suborg}</h2>
              </div>

              {expandedSuborgs[suborg] && (
                <div>
                  {filteredSpreadsheets
                    .filter((spreadsheet) => (spreadsheet.suborganisation || 'No Suborganisation') === suborg)
                    .map((spreadsheet) => {
                      // Compute selected and sorted columns
                      const selectedColumns = columnStatuses[spreadsheet.id]
                        .map((status, index) => ({ status, index }))
                        .filter(({ status }) => ['Date', 'Categorical', 'NumericalEditable', 'CategoricalEditable'].includes(status))
                        .sort((a, b) => {
                          const order = { Date: 1, Categorical: 2, CategoricalEditable: 3, NumericalEditable: 4 };
                          return order[a.status] - order[b.status];
                        });

                      return (
                        <div key={spreadsheet.id} className='spreadsheet-container'>
                          <h3>{spreadsheet.newName}</h3>
                          <div className="table-container">
                            <table className="compact-data-table" id={`spreadsheet-${spreadsheet.id}`}>
                              <thead>
                                <tr>
                                  <th>Original</th>
                                  {selectedColumns.map(({ index }) => (
                                    <th key={index}>
                                      {originalHeaders[spreadsheet.id][index]}
                                    </th>
                                  ))}
                                </tr>
                                <tr>
                                  <th>Editable</th>
                                  {selectedColumns.map(({ index }) => {
                                    const header = localHeaders[spreadsheet.id][index];
                                    const status = columnStatuses[spreadsheet.id][index];

                                    return (
                                      <th key={index} className="editable-header">
                                        <div className="editable-header-container" data-key={`${spreadsheet.id}-${index}`}>
                                          <AutosuggestDropdown
                                            options={[...new Set(editableColumnNames)]}  // Use the filtered and combined headers from all spreadsheets
                                            initialValue={header}
                                            onSelect={(value) => handleHeaderChange(spreadsheet.id, index, value)}
                                            onFocus={() => handleHeaderFocus(spreadsheet.id, index)}
                                            onBlur={() => handleHeaderBlur(spreadsheet.id, index)}
                                            showDropdown={dropdownVisible[spreadsheet.id] === index}
                                            isValid={!invalidHeaders[`${spreadsheet.id}-${index}`]}
                                          />
                                          <div className="category-status">
                                            {status === 'Date' || status === 'Categorical' ? (
                                              <>
                                                <span>{status}</span>
                                                <div className="remove-link" onClick={() => handleRemove(spreadsheet.id, index)}>
                                                  <span style={{ color: 'red', cursor: 'pointer' }}>Remove</span>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <select
                                                  value={status}
                                                  onChange={(e) => handleStatusChange(spreadsheet.id, index, e.target.value)}
                                                >
                                                  <option value="NumericalEditable">Numerical</option>
                                                  <option value="CategoricalEditable">Categorical</option>
                                                </select>
                                                <div className="remove-link" onClick={() => handleRemove(spreadsheet.id, index)}>
                                                  <span style={{ color: 'red', cursor: 'pointer' }}>Remove</span>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </th>
                                    );
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={selectedColumns.length + 1 || 1}>
                                    <button onClick={() => handleSave(spreadsheet.id)}>Save</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );  
}

export default ColumnMappingPage;
